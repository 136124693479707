export default function flatten<T>(carry?: T[], items?: T[] | T): T[] {
	if (!carry) {
		carry = []
	}

	if (Array.isArray(items)) {
		return carry.concat(items.reduce(flatten, [] as T[]))
	}

	carry.push(items as T)

	return carry
}
