


















































































































































































































import Modal from './Modal.vue'
import { cloneDeep } from 'lodash'
import { Component, Vue, Prop } from 'vue-property-decorator'

const getDefaultOverlayOptions = () => ({
	WatermarkAnnotation: [false],
	MeasurementAnnotation: [false]
})

@Component({ components: { Modal } })
export default class ImageEditModal extends Vue {
	@Prop({ type: String })
	readonly applyText!: string

	@Prop({ type: String })
	readonly cancelText!: string

	protected rule: ImageRule = {}

	protected resolveCallback: Function = () => ({})
	protected rejectCallback: Function = () => ({})

	protected units = {
		top: '%',
		right: '%',
		bottom: '%',
		left: '%'
	}

	protected appliedOverlays = {
		WatermarkAnnotation: false,
		BrandLogoAnnotation: false,
		BigReviveLogoAnnotation: false,
		MeasurementAnnotation: false
	}

	protected overlayOptions = getDefaultOverlayOptions()

	get defaultOverlayOptions() {
		return getDefaultOverlayOptions()
	}

	get globalPadding() {
		return this.$settings.platforms.padding
	}

	openModal(rule: ImageRule) {
		const applied = (rule.overlays || [])
			.map(a => ({
				name: a.name,
				args: JSON.parse(JSON.stringify(a.args || []))
			}))
			.reduce((applied, { name, args }) => {
				applied[name] = args

				return applied
			}, {})

		this.rule = Object.assign({ useAsIs: false }, cloneDeep(rule))

		this.rule.padding = Object.assign(
			{
				top: this.globalPadding,
				right: this.globalPadding,
				bottom: this.globalPadding,
				left: this.globalPadding
			},
			this.rule.padding
		)

		this.units = {
			top: this.rule.padding.top < 1 ? '%' : 'px',
			right: this.rule.padding.right < 1 ? '%' : 'px',
			bottom: this.rule.padding.bottom < 1 ? '%' : 'px',
			left: this.rule.padding.left < 1 ? '%' : 'px'
		}

		this.rule.padding = {
			top:
				this.units.top === '%'
					? this.rule.padding.top * 100
					: this.rule.padding.top,
			right:
				this.units.right === '%'
					? this.rule.padding.right * 100
					: this.rule.padding.right,
			bottom:
				this.units.bottom === '%'
					? this.rule.padding.bottom * 100
					: this.rule.padding.bottom,
			left:
				this.units.left === '%'
					? this.rule.padding.left * 100
					: this.rule.padding.left
		}

		Object.keys(this.appliedOverlays).forEach(overlay => {
			this.appliedOverlays[overlay] =
				typeof applied[overlay] !== 'undefined'

			this.overlayOptions[overlay] =
				typeof applied[overlay] !== 'undefined'
					? cloneDeep(applied[overlay])
					: this.defaultOverlayOptions[overlay] || []
		})

		return new Promise((resolve, reject) => {
			this.resolveCallback = resolve
			this.rejectCallback = reject

			this.$refs.base.open()
		})
	}

	closeModal() {
		this.$refs.base.close()
	}

	makeSelection() {
		const selection: StringifiedAnnotation[] = []

		Object.keys(this.appliedOverlays).forEach(overlay => {
			if (this.appliedOverlays[overlay]) {
				selection.push({
					name: overlay,
					args: this.overlayOptions[overlay] || []
				})
			}
		})

		return selection
	}

	apply() {
		this.rule.padding = {
			top:
				this.units.top === '%'
					? this.rule.padding.top / 100
					: this.rule.padding.top,
			right:
				this.units.right === '%'
					? this.rule.padding.right / 100
					: this.rule.padding.right,
			bottom:
				this.units.bottom === '%'
					? this.rule.padding.bottom / 100
					: this.rule.padding.bottom,
			left:
				this.units.left === '%'
					? this.rule.padding.left / 100
					: this.rule.padding.left
		}

		this.resolveCallback(
			Object.assign(this.rule, { overlays: this.makeSelection() })
		)

		this.closeModal()
	}

	cancel() {
		this.resolveCallback(null)

		this.closeModal()
	}
}
