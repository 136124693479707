import Vue from 'vue'
import { Module } from 'vuex'
import desc from '@/helpers/desc'
import ProductImage from '@/types/ProductImage'
import * as platforms from './platform-settings'
import { ImageRule } from '@/types/definitions/ImageRule'
import { PlatformType } from '@/types/definitions/PlatformType'
import { PlatformImage } from '@/types/definitions/PlatformImage'
import { StoredProductType } from '@/types/definitions/StoredProductType'

export type PlatformsState = {
	platforms: { [key: string]: PlatformType }
}

const getPlatformState = () => ({
	'used-design': platforms.UsedDesign,
	amazon: platforms.Amazon,
	'e-k-v2': platforms.EbayKleinanzeigenV2,
	'moebel-de': platforms.MoebelDe,
	'1stdibs': platforms.Firstdibs,
	vinterior: platforms.Vinterior,
	ebay: platforms.Ebay,
	'ebay-unbranded': platforms.EbayUnbranded,
	shopify: platforms.Shopify,
	willhaben: platforms.Willhaben,
	google: platforms.Google,
	facebook: platforms.Facebook,
	pamono: platforms.Pamono
})

export default {
	namespaced: true,

	state: {
		platforms: getPlatformState()
	},

	getters: {
		byId(state: PlatformsState) {
			return (id: string) => state.platforms[id]
		},

		image(state: PlatformsState) {
			return (id: string, index: number) =>
				state.platforms[id].images[index]
		},

		platforms(state: PlatformsState) {
			return Object.values(state.platforms)
		},

		data(state: PlatformsState) {
			return Object.values(state.platforms).map(p => p.data)
		},

		storables(state: PlatformsState) {
			return Object.values(state.platforms).map(p => p.storable)
		},

		selectedImages(state: PlatformsState) {
			const selectedImages = [] as {
				platform: string
				ix: number
				image: PlatformImage
			}[]

			Object.keys(state.platforms).map(platform =>
				state.platforms[platform].images.forEach(
					(i, ix) =>
						i &&
						i.selected &&
						selectedImages.push({ platform, ix, image: i })
				)
			)

			return selectedImages
		},

		hasSelectedImages(_state, getters) {
			return !!getters.selectedImages.length
		}
	},

	mutations: {
		UPDATE_PLATFORM(
			state: PlatformsState,
			platform: {
				id: PlatformType['id']
				[key: string]: any
			}
		) {
			Object.keys(platform).forEach(key => {
				Vue.set(state.platforms[platform.id], key, platform[key])
			})
		},

		SET_IMAGE(
			state: PlatformsState,
			data: {
				id: PlatformType['id']
				data?: PlatformImage | null
				ix: number
			}
		) {
			if (data.data) {
				Vue.set(state.platforms[data.id].images, data.ix, data.data)
			} else {
				Vue.delete(state.platforms[data.id].images, data.ix)
			}
		},

		CLEAR(state) {
			Object.keys(state.platforms).forEach(platformId => {
				state.platforms[platformId].images
					.map((_i, key) => key)
					.sort(desc)
					.forEach(i =>
						Vue.delete(state.platforms[platformId].images, i)
					)
			})
		}
	},

	actions: {
		LOAD(
			{ commit, state, rootGetters },
			{ platforms, images }: StoredProductType
		) {
			return Promise.all(
				Object.keys(platforms).map(
					id =>
						new Promise(resolve => {
							const platformImages =
								images[id] ||
								([] as Array<
									PlatformImage & {
										order: number
										original: string
									}
								>)

							const imageList = Array(
								state.platforms[id].settings.maxCount
							).map(() => ({}))

							platformImages.forEach(i => {
								let rule = i.rule || {}

								if (typeof rule === 'string') {
									rule = JSON.parse(
										rule as string
									) as ImageRule
								}

								rule.overlays = rule.overlays

								imageList[i.order] = {
									image: rootGetters['images/byId'](
										i.original
									),
									rule: rule,
									selected: false
								}
							})

							commit(
								'UPDATE_PLATFORM',
								Object.assign({ id }, platforms[id], {
									images: []
								})
							)

							imageList.forEach((img, ix) => {
								commit('SET_IMAGE', { id, ix, data: img })
							})

							resolve()
						})
				)
			)
		},

		ADD_IMAGE(
			{ rootGetters, getters, commit },
			imageData: { id: PlatformType['id']; image: ProductImage['id'] }
		) {
			const p = getters.byId(imageData.id)

			if (p) {
				const ix = p.images.reduce(
					(c: number, i: PlatformImage | {}, ix: number) =>
						Object.keys(i).length ? c : Math.min(c, ix),
					p.images.length
				)

				const image = rootGetters['images/byId'](imageData.image)

				commit('SET_IMAGE', {
					ix,
					id: imageData.id,
					data: {
						image,
						rule: {
							allowFullPicture: image.autoCategory === 'detail',
							padding: {
								top: image.autoCategory === 'detail' ? 0 : 0.3,
								bottom:
									image.autoCategory === 'detail' ? 0 : 0.3
							},
							custom: true,
							overlays: p.settings.defaultOverlays || []
						},
						selected: false
					}
				})
			}
		},

		REMOVE_IMAGE(
			{ getters, commit },
			imageData: { id: PlatformType['id']; index: number }
		) {
			const p = getters.byId(imageData.id)

			if (p) {
				commit('SET_IMAGE', {
					ix: imageData.index,
					id: imageData.id
				})
			}
		},

		EDIT_IMAGE(
			{ getters, commit },
			imageData: {
				id: PlatformType['id']
				index: keyof PlatformType['images']
				rule: ImageRule
			}
		) {
			const p = getters.byId(imageData.id)

			if (p) {
				const image = getters.image(imageData.id, imageData.index)

				image.rule = Object.assign({}, imageData.rule, { custom: true })

				commit('SET_IMAGE', {
					ix: imageData.index,
					id: imageData.id,
					data: image
				})
			}
		}
	}
} as Module<PlatformsState, unknown>
