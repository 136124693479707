import store from '../store'
import { NavigationGuard, Route } from 'vue-router'

export default (function CheckAuthentication(
	to: Route,
	_from: Route,
	next: Function
) {
	if (to.name === 'Login' || store.getters.authenticated) {
		return next()
	}

	store.commit('SET_RETURN_TO', to.fullPath)

	next('/login')
} as NavigationGuard)
