import { Module } from 'vuex'
import axios from '@/helpers/axios'

export type AuthState = {
	token: string
	returnTo?: string
}

export default {
	state: {
		token: '',
		returnTo: undefined
	},

	getters: {
		authenticated(state) {
			return !!state.token
		}
	},

	mutations: {
		SET_API_TOKEN(state, token: string) {
			state.token = token
		},

		SET_RETURN_TO(state, returnTo: string) {
			state.returnTo = returnTo
		}
	},

	actions: {
		LOGIN({ commit }, credentials: { email: string; password: string }) {
			return axios.post('auth', credentials).then(response => {
				commit('SET_API_TOKEN', response.data)

				return response.data
			})
		},

		LOGOUT({ commit }) {
			commit('SET_API_TOKEN', '')
		}
	}
} as Module<AuthState, unknown>
