import Area from './Area'
import Point from './Point'

export default class LocalizedObjectAnnotation
	implements LocalizedObjectAnnotationType {
	public mid: LocalizedObjectAnnotationType['mid']
	public name: LocalizedObjectAnnotationType['name']
	public score: LocalizedObjectAnnotationType['score']
	public boundingPoly: LocalizedObjectAnnotationType['boundingPoly']

	constructor(
		mid:
			| typeof LocalizedObjectAnnotation
			| LocalizedObjectAnnotationType
			| LocalizedObjectAnnotationType['mid'],
		name?: LocalizedObjectAnnotationType['name'],
		score?: LocalizedObjectAnnotationType['score'],
		boundingPoly?: LocalizedObjectAnnotationType['boundingPoly']
	) {
		if (typeof mid !== 'string') {
			;({
				mid,
				name,
				score,
				boundingPoly
			} = mid as LocalizedObjectAnnotationType)
		}

		this.mid = mid as LocalizedObjectAnnotationType['mid']
		this.name = name as LocalizedObjectAnnotationType['name']
		this.score = score as LocalizedObjectAnnotationType['score']
		this.boundingPoly = boundingPoly as LocalizedObjectAnnotationType['boundingPoly']
	}

	static rehydrate(data: LocalizedObjectAnnotationType) {
		return new LocalizedObjectAnnotation(data)
	}

	toArea(width: number | DimensionType, height: number = 0): Area {
		if (typeof width !== 'number') {
			;({ width, height } = width)
		}

		return new Area(
			new Point(
				Math.round(
					(this.boundingPoly.normalizedVertices[0].x || 0) * width
				),
				Math.round(
					(this.boundingPoly.normalizedVertices[0].y || 0) * height
				)
			),
			new Point(
				Math.round(
					(this.boundingPoly.normalizedVertices[2].x || 0) * width
				),
				Math.round(
					(this.boundingPoly.normalizedVertices[2].y || 0) * height
				)
			)
		)
	}
}
