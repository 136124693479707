import { Module } from 'vuex'
import Notification from '@/types/Notification'
import { NotificationType } from '@/types/definitions/NotificationType'

export type NotificationState = {
	notifications: NotificationType[]
}

export default {
	namespaced: true,

	state: {
		notifications: []
	},

	mutations: {
		PUSH(state: NotificationState, notification: NotificationType) {
			state.notifications.push(notification)
		},

		REMOVE(state, notification: NotificationType | string) {
			if (typeof notification !== 'string') {
				notification = notification.id
			}

			state.notifications = state.notifications.filter(
				n => n.id !== notification
			)
		},

		CLEAR(state) {
			state.notifications = []
		}
	},

	actions: {
		ADD({ commit }, notification: NotificationType) {
			commit('PUSH', new Notification(notification))

			if (notification.timer) {
				setTimeout(
					() => commit('REMOVE', notification),
					notification.timer
				)
			}
		}
	}
} as Module<NotificationState, unknown>
