import Platform from '@/types/Platform'

export default Platform.fromStorable({
	id: 'amazon',
	settings: {
		logo: require('@/assets/logo-amazon.png'),
		name: 'Amazon',
		format: {
			width: 2500,
			height: 2500
		},
		maxCount: 9,
		defaultOverlays: [{ name: 'WatermarkAnnotation', args: [true] }],
		setRules: {
			'0': [
				{
					category: ['special', 'title'],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					}
				}
			]
		},
		rules: {
			'0': [
				{
					category: ['special', 'title'],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					}
				}
			],
			'1': [
				{
					category: ['special', 'measurements'],
					allowFullPicture: true,
					padding: {
						top: 0.24,
						bottom: 0.31
					},
					overlays: [
						{ name: 'MeasurementAnnotation', args: [true] }
					]
				}
			],
			'2': [
				{
					category: ['functions', null],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					},
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			],
			'3': [
				{
					category: ['special', 'materialDetail'],
					allowFullPicture: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			],
			'4': [
				{
					category: ['detail', null],
					allowFullPicture: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			],
			'5': [
				{
					category: ['detail', 'damages'],
					allowFullPicture: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			],
			'6': [
				{
					category: ['detail', 'damages'],
					allowFullPicture: true,
					allowFindNext: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			],
			'7': [
				{
					category: ['special', 'colors'],
					allowFullPicture: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			],
			'8': [
				{
					category: ['special', 'logo'],
					allowFullPicture: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			]
		},
		fillRule: [
			{
				category: ['alternate', null],
				allowFullPicture: true,
				padding: {
					top: 0.2,
					bottom: 0.2
				},
				overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
			}
		]
	}
})
