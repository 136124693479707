
























import axios from '@/helpers/axios'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-regular-svg-icons'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'

addFontAwesomeIcon(faSave)
addFontAwesomeIcon(faCheck)

@Component
export default class SaveButton extends Vue {
	protected saving = false
	protected progress = 0
	protected step = ''
	protected successful = false
	protected formData!: FormData

	get images() {
		return this.$store.state.images.images
	}

	/**
	 * Visually start the saving process, but really decouple it by using a
	 * 50ms timeout, so that it doesn't lag so much.
	 */
	save() {
		if (this.saving) {
			this.$store.dispatch('notifications/ADD', {
				message: this.$t(
					'Please wait for the saving process to be done before attempting a new save'
				),
				type: 'warning',
				scrollUp: true
			})

			return
		}

		this.progress = 0
		this.saving = true

		this.gatherData()
			.then(() => (this.step = 'Uploading images'))
			.then(() => this.sendDataToServer())
			.then(() => (this.progress = 100))
			.then(() => (this.successful = true))
			.then(() =>
				this.$store.dispatch('notifications/ADD', {
					type: 'success',
					message: this.$t('Successfully saved product')
				})
			)
			.then(() => setTimeout(() => (this.successful = false), 5000))
			.catch(this.$handleError)
			.finally(() => (this.saving = false))
	}

	gatherData() {
		this.formData = new FormData()

		const requiredPromises = this.attachOriginals()
		const optionalPromises = this.attachPlatformImages()

		this.attachPlatformData()

		return Promise.all([
			Promise.all(requiredPromises),
			Promise.allSettled(optionalPromises)
		])
	}

	sendDataToServer() {
		return axios.post(
			`${this.$store.getters.product.sku}/save`,
			this.formData,
			{
				onUploadProgress: this.uploadProgress
			}
		)
	}

	@Watch('progress', { immediate: true })
	selectStep(n) {
		switch (n) {
			case 0:
				this.step = 'Generating images'
				break

			case 70:
				this.step = 'Processing images on server'
				break

			case 99:
				this.step = 'Copying images to final destination'
				break

			case 100:
				this.step = 'Done'
				break
		}
	}

	uploadProgress(ev) {
		if (ev.lengthComputable) {
			this.progress = Math.round(70 * (ev.loaded / ev.total))
		}

		if (this.progress === 70) {
			const increase = () => {
				if (this.progress && this.progress < 99) {
					this.progress++

					setTimeout(increase, 100)
				}
			}

			setTimeout(increase, 100)
		}
	}

	attachPlatformData() {
		this.$store.getters['platforms/data'].forEach(platform => {
			this.formData.set(
				`platforms[${platform.id}][title]`,
				platform.title
			)

			this.formData.set(
				`platforms[${platform.id}][active]`,
				platform.active
			)

			this.formData.set(
				`platforms[${platform.id}][custom]`,
				platform.custom
			)
		})
	}

	attachOriginals() {
		return this.images
			.filter(i => i.category !== 'permanent')
			.map(this.attachOriginal)
	}

	attachOriginal(i): Promise<any> {
		const imageData = this.getOriginalData(i)

		this.formData.set(`originals[${i.id}][data]`, imageData)

		if (i.image.isLink) {
			return Promise.resolve(
				this.formData.set(`originals[${i.id}][source]`, i.image.src)
			)
		}

		return i.image.blob.then(blob =>
			this.formData.set(`originals[${i.id}][image]`, blob)
		)
	}

	getOriginalData(i) {
		return JSON.stringify({
			objects: i.objects,
			categories: i.categories,
			order: i.order,
			dimensions: {
				width: i.dimensions.width,
				height: i.dimensions.height
			}
		})
	}

	attachPlatformImages() {
		const platforms = this.$store.state.platforms.platforms

		return Object.values(platforms).flatMap(platform =>
			this.getPlatformImages(platform)
		)
	}

	getPlatformImages(platform: PlatformType) {
		return platform.images.map((image, ix) =>
			this.getFinalPlatformImage(image, ix, platform)
		)
	}

	getFinalPlatformImage(
		imageObject: PlatformImage,
		ix: number,
		platform: PlatformType
	) {
		if (!imageObject || !imageObject.image) {
			// eslint-disable-next-line no-console
			console.error(
				`An unknown error occurred with image ${ix} for ${platform.settings.name}`
			)

			return Promise.reject('Unknown error')
		}

		const {
			image: { id, image: imageSource },
			rule
		} = imageObject

		const formDataPrefix = `platforms[${platform.id}][images][${ix}]`

		return this.$store
			.dispatch('images/GET_IMAGE_COMPOSITION_FOR_SAVING', {
				rule,
				id,
				dimensions: platform.settings.format,
				globalPadding: this.$settings.platforms.padding,
				boundingBoxPadding: this.$settings.gallery.boundingBoxPadding
			})
			.then(({ image, key }) => {
				this.formData.set(`${formDataPrefix}[image]`, key)

				if (image.isLink) {
					return Promise.resolve(
						this.formData.set(`images[${key}]`, image.src)
					)
				}

				// If the image data has already been set for this specific key, do not
				// request a new blob. URLs to images, however are always preferred, so
				// we allow them to overwrite the old data.
				if (this.formData.has(`images[${key}]`)) {
					return Promise.resolve()
				}

				return image.blob.then(blob =>
					this.formData.set(`images[${key}]`, blob)
				)
			})
			.then(() => {
				rule.version = process.env.VUE_APP_VERSION

				this.formData.set(
					`${formDataPrefix}[rule]`,
					JSON.stringify(rule)
				)
				this.formData.set(`${formDataPrefix}[original]`, id)
			})
			.catch(e => {
				this.$handleError(e)

				this.formData.delete(`${formDataPrefix}[image]`)
				this.formData.delete(`${formDataPrefix}[rule]`)
				this.formData.delete(`${formDataPrefix}[original]`)
				this.formData.delete(`${formDataPrefix}[order]`)
			})
	}
}
