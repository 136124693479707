import Platform from '@/types/Platform'

export default Platform.fromStorable({
	id: 'ebay-unbranded',
	settings: {
		logo: require('@/assets/logo-ebay-unbranded.png'),
		name: 'ebay-unbranded',
		format: {
			width: 2500,
			height: 2500
		},
		maxCount: 1,
		defaultOverlays: [{ name: 'WatermarkAnnotation', args: [true] }],
		setRules: {
			'0': [
				{
					category: ['special', 'title'],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					}
				}
			]
		},
		rules: {
			'0': [
				{
					category: ['special', 'title'],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					}
				}
			]
		}
	}
})
