import store from '@/store'
import flatten from './flatten'
import axios, { AxiosError } from 'axios'
import Notification from '@/types/Notification'

const a = axios.create({
	baseURL: process.env.VUE_APP_BACKEND_BASE
})

a.interceptors.request.use(r => {
	r.headers = Object.assign(
		{ Authorization: `Bearer ${store.state.auth.token}` },
		r.headers
	)

	return r
})

a.interceptors.response.use(
	r => r, // The success interceptor
	(r: AxiosError) => {
		if (r.response && [401, 403].includes(r.response.status)) {
			return Promise.resolve(store.dispatch('LOGOUT')).then(() => {
				// @ts-ignore
				throw r.response
			})
		}

		if (r.response && r.response.status === 422) {
			let errors = `<strong>${r.response.data.message}</strong>`

			errors += '<ul class="list-inside list-disc mt-2 pl-2">'

			Object.values(r.response.data.errors as string[][])
				.reduce(flatten, [])
				.forEach(m => {
					errors += `<li>${m}</li>`
				})

			errors += '</ul>'

			store.dispatch(
				'notifications/ADD',
				new Notification(errors, 'error')
			)

			return Promise.reject(r.response)
		}

		if (r.response && r.response.status === 500) {
			const data = r.response.data

			try {
				// eslint-disable-next-line no-console
				console.error(
					`${data.exception}: ${data.message} in ${data.file}:${data.line}`,
					data.trace
				)
			} catch {}
		}

		if (r.response && r.response.status === 404) {
			store.dispatch(
				'notifications/ADD',
				new Notification(`Not found`, 'error')
			)

			return Promise.reject()
		}

		if (r.response && r.response.status === 413) {
			store.dispatch(
				'notifications/ADD',
				new Notification(
					`
						Too much data for the server.
						Please notify the developer and don't close the site.
						This is easily and quickly fixable without losing any data.
					`,
					'error'
				)
			)

			return Promise.reject()
		}

		return Promise.reject(r)
	}
)

export default a
