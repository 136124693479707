import { Route } from 'vue-router'

const appName = process.env.VUE_APP_NAME ? ` | ${process.env.VUE_APP_NAME}` : ''

export default function PageTitle(to: Route, _from: Route) {
	if (to.meta.title) {
		document.title = to.meta.title + appName
	} else if (to.name) {
		document.title = to.name + appName
	}
}
