import Area from '@/types/Area'
import Point from '@/types/Point'
import chunk from '@/helpers/chunk'
import { tap } from '@/helpers/tap'
import truthy from '@/helpers/truthy'
import unique from '@/helpers/unique'
import sortBy from '@/helpers/sort-by'
import flatten from '@/helpers/flatten'
import toArray from '@/helpers/to-array'
import ProductImage from '@/types/ProductImage'
import DropZone from '@/components/DropZone.vue'
import { Component, Vue } from 'vue-property-decorator'
import { ProductImageInterface } from '@/types/definitions/ProductImageInterface'

type FileSystemFileEntry = {
	file(success: (file: File) => any, error: (e: Error) => any): void
	fullPath: string
	createReader: () => { readEntries: Callback<FileSystemFileEntry[], Error> }
	isFile: boolean
}

// @ts-ignore
@Component({ components: { DropZone } })
export default abstract class FileDrop extends Vue {
	protected showDropZone: boolean = false

	abstract $root: object & Record<never, any> & Vue & { sorting: boolean }
	abstract images: ProductImageInterface[]
	abstract loading: { [key: string]: number }
	abstract progress: number
	abstract progressMax: number
	abstract $commit: Function
	abstract $handleError: (error: any) => Promise<null>
	abstract $dispatch: Function
	abstract categories: string[]
	abstract displayLimit: number
	abstract imageCount: number
	abstract promptForIds: (ids: string[] | number[]) => any
	abstract get byCategoryAndSub(): (
		category: string,
		subCategory: string | null
	) => ProductImageInterface[]

	newDrop(drop: DragEvent & { dataTransfer: { items: DataTransferItem[] } }) {
		if (this.$root.sorting) {
			return
		}

		const imageCount = this.imageCount

		const filelist = toArray(drop.dataTransfer.items).map(
			(item: DataTransferItem): FileSystemFileEntry =>
				item.webkitGetAsEntry()
		)

		this.getFileList(filelist)
			.then(tap(this.determineProduct))
			.then(this.getProductImages)
			.then(this.finishDrop)

		if (imageCount) {
			this.askForConfirmation()
		}
	}

	determineProduct(filelist: Array<FileSystemFileEntry>) {
		if (this.$store.getters.product.sku) {
			return
		}

		const ids = filelist
			.map(f => f.fullPath)
			.map(
				p =>
					Array.from(p.matchAll(/\/(\d{3,6})(?:[ \/]|$)/gi)) as [
						string,
						string
					][]
			)
			.flat(1)
			.map((i: [string, string]) => i[1])
			.reduce(unique, [] as string[])

		if (ids.length === 1) {
			return this.$router.replace(`/${ids[0]}`)
		} else {
			return this.promptForIds(ids)
		}
	}

	askForConfirmation() {
		const imageCount = this.imageCount
		;(<Vue & { openModal: () => Promise<boolean> }>this.$refs.confirm)
			.openModal()
			.then(
				shouldAdd =>
					!shouldAdd &&
					this.$store.commit('images/SLICE_IMAGES', imageCount)
			)
			.catch((e: Error) => {
				e && console.error(e) // eslint-disable-line no-console
			})
	}

	finishDrop(items: Promise<BoundingBoxWorkerResult>[]) {
		items = items.map(item =>
			item.then(
				tap(() => {
					this.loading.images--
					this.progress++
				})
			)
		)

		Promise.allSettled(items)
			.then(
				tap(items => {
					this.progressMax -= items.length
					this.progress = this.progressMax
						? <number>this.progress - items.length
						: 0
				})
			)
			.then(items => {
				this.categories.forEach(
					this.$dispatch('images/RESET_ORDER_BY_NAME')
				)

				const ids = items
					.map(i => i.status === 'fulfilled' && i.value.id)
					.filter(truthy)

				this.autoCategorize(ids as string[])
			})
	}

	getProductImages(
		items: FileSystemFileEntry[]
	): Promise<BoundingBoxWorkerResult>[] {
		this.progressMax += items.length
		this.loading.images += items.length

		return items.map(this.getFileContentsAndConvert)
	}

	getFileContentsAndConvert(item: FileSystemFileEntry) {
		return this.getFile(item)
			.then(this.getFileContents)
			.then(ProductImage.fromFile)
			.then(this.$commit('images/PUSH_IMAGE'))
			.then((image: ProductImage) =>
				this.$store.dispatch('images/REANNOTATE_IMAGE', image.id)
			)
			.catch(e => {
				if (e !== 'File must be an image') {
					throw e
				}
			})
	}

	getFile(
		fileEntry: FileSystemFileEntry
	): Promise<{ file: File; fullPath: string }> {
		return new Promise((resolve, reject) =>
			fileEntry.file(
				(file: File) => resolve({ file, fullPath: fileEntry.fullPath }),
				reject
			)
		)
	}

	getFileContents({
		file
	}: {
		file: File
	}): Promise<{ contents: string; name: string; type: string }> {
		if (!file.type.startsWith('image/')) {
			throw 'File must be an image'
		}

		return new Promise((resolve, reject) => {
			const reader = new FileReader()

			reader.addEventListener('loadend', () =>
				resolve({
					contents: <string>reader.result,
					name: file.name,
					type: file.type
				})
			)
			reader.addEventListener('error', reject)

			reader.readAsDataURL(file)
		})
	}

	getFileList(
		entry: FileSystemFileEntry[] | FileSystemFileEntry
	): Promise<FileSystemFileEntry[]> {
		if (Array.isArray(entry)) {
			return Promise.all(entry.map(this.getFileList)).then(entries =>
				entries.reduce(flatten, [])
			)
		}

		return new Promise((resolve, reject) => {
			if (entry.isFile) {
				return resolve([entry])
			}

			entry
				.createReader()
				.readEntries((entries: FileSystemFileEntry[]) => {
					Promise.all(
						entries.map(entry =>
							this.getFileList(
								entry
							).then((entries: FileSystemFileEntry[]) =>
								entries.reduce(<any>flatten, [])
							)
						)
					)
						.then(<any>resolve)
						.catch(reject)
				})
		})
	}

	detectClippedTitleImage(
		ids: ProductImageInterface['id'][] = this.$store.getters['images/ids']
	): Promise<ProductImageInterface['id']> {
		if (this.byCategoryAndSub('special', 'title').length) {
			return Promise.reject()
		}

		const byId = this.$store.getters['images/byId'] as (
			a: string
		) => ProductImageInterface

		return new Promise(async (resolve, reject) => {
			const images = ids
				.map(id => byId(id))
				.filter(i => i.autoCategory === 'alternate')

			for (const image of images) {
				if (await this.hasWhiteCorners(image)) {
					return resolve(image.id)
				}
			}

			reject()
		})
	}

	hasWhiteCorners(image: ProductImageInterface) {
		return image.image
			.getCanvasWithImage()
			.then(([_c, ctx, dimensions]) => {
				const firstTest = new Area(new Point(3, 3), new Point(6, 6))

				const secondTest = new Area(
					new Point(
						dimensions.width - firstTest.end.x,
						dimensions.height - firstTest.end.y
					),
					new Point(
						dimensions.width - firstTest.start.x,
						dimensions.height - firstTest.start.y
					)
				)

				const firstResult = ctx
					.getImageData(
						firstTest.start.x,
						firstTest.start.y,
						firstTest.width,
						firstTest.height
					)
					.data.reduce(chunk(4), [])
					.map(([r, g, b]) => (r & g & b) === 255)
					.reduce((c, v) => c + ~~v, 0)

				const secondResult = ctx
					.getImageData(
						secondTest.start.x,
						secondTest.start.y,
						secondTest.width,
						secondTest.height
					)
					.data.reduce(chunk(4), [])
					.map(([r, g, b]) => (r & g & b) === 255)
					.reduce((c, v) => c + ~~v, 0)

				return firstResult >= 8 && secondResult >= 8
			})
	}

	detectUnclippedTitleImage(
		ids: ProductImageInterface['id'][] = this.$store.getters['images/ids'],
		clippedId?: ProductImageInterface['id']
	): Promise<ProductImageInterface['id']> {
		if (this.byCategoryAndSub('special', 'title-unclipped').length) {
			return Promise.reject()
		}

		const byId = this.$store.getters['images/byId'] as (
			a: string
		) => ProductImageInterface

		return new Promise((resolve, reject) => {
			const image = ids
				.map(id => byId(id))
				.find(i => i.autoCategory === 'alternate' && i.id !== clippedId)

			if (image) {
				return resolve(image.id)
			}

			reject()
		})
	}

	detectMeasurementsImage(
		ids: ProductImageInterface['id'][] = this.$store.getters['images/ids'],
		hasClipped?: boolean
	): Promise<ProductImageInterface['id']> {
		if (this.byCategoryAndSub('special', 'measurements').length) {
			return Promise.reject()
		}

		const byId = this.$store.getters['images/byId'] as (
			a: string
		) => ProductImageInterface

		return new Promise((resolve, reject) => {
			const image = ids
				.map(id => byId(id))
				.filter(i => i.autoCategory === 'alternate')
				.filter(i => !hasClipped || i.subCategory !== 'title')
				.slice(1) // Skip the first image. It is supposed to be the title image.
				.shift()

			if (image) {
				return resolve(image.id)
			}

			reject()
		})
	}

	detectColorsImage(
		ids: ProductImageInterface['id'][] = this.$store.getters['images/ids']
	): Promise<ProductImageInterface['id']> {
		if (this.byCategoryAndSub('special', 'colors').length) {
			return Promise.reject()
		}

		const byId = this.$store.getters['images/byId'] as (
			a: string
		) => ProductImageInterface

		const colorCat = ['/m/0hf58v5', '/j/5qg9b8', '/m/0n5v01m']

		return new Promise((resolve, reject) => {
			const image = ids
				.map(id => byId(id))
				.sort(sortBy('completeObjectCoverage', 'desc'))
				.find(i => i.objects.find(o => colorCat.includes(o.mid)))

			if (image) {
				return resolve(image.id)
			}

			reject()
		})
	}

	categorize(
		id: ProductImageInterface['id'],
		categories: Category,
		order = 0
	) {
		this.$store.commit('images/UPDATE_IMAGE', {
			id,
			categories,
			order
		})
	}

	autoCategorize(ids?: ProductImageInterface['id'][]) {
		this.detectClippedTitleImage(ids)
			.then(tap(id => this.categorize(id, ['special', 'title'])))
			.catch(() => undefined)
			.then((clippedId?: ProductImageInterface['id']) =>
				Promise.allSettled([
					this.detectUnclippedTitleImage(ids, clippedId).then(id =>
						this.categorize(id, ['special', 'title-unclipped'])
					),
					this.detectMeasurementsImage(ids, !!clippedId).then(id =>
						this.categorize(id, ['special', 'measurements'])
					),
					this.detectColorsImage(ids).then(id =>
						this.categorize(id, ['special', 'colors'])
					)
				])
			)
	}
}
