import store from '@/store'
import { NavigationGuard, Route } from 'vue-router'

export default (async function WaitForStore(
	_to: Route,
	_from: Route,
	next: Function
) {
	const checkStore = () => {
		if (store) {
			return next()
		}

		setTimeout(checkStore, 50)
	}

	checkStore()
} as NavigationGuard)
