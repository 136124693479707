
























import Platform from './Platform.vue'
import PlatformsHeader from './PlatformsHeader.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Platforms as PlatformDefinition } from '@/types/definitions/platform'

@Component({ components: { PlatformsHeader, Platform } })
export default class Platforms extends Vue {
	get platforms() {
		return Object.keys(this.$store.state.platforms.platforms)
	}

	autoset(onlyNonCustom: boolean = false) {
		let platforms = this.$refs.platforms

		if (onlyNonCustom) {
			platforms = platforms.filter(p => !p.platform.custom)
		}

		platforms.forEach(p => p.reset())
	}

	deactivateToggle() {
		const allDeactivated = !this.$refs.platforms
			.map(p => p.platform.active)
			.includes(true)

		this.$refs.platforms
			.map(p => p.platform)
			.forEach(p =>
				this.$store.commit('platforms/UPDATE_PLATFORM', {
					id: p.id,
					active: allDeactivated
				})
			)
	}

	clear() {
		this.$store.commit('platforms/CLEAR')
	}
}
