import ImageData from '@/types/ImageData'
import ImageAnnotation from '@/types/ImageAnnotation'
import scaleDimensions from '@/helpers/scale-dimensions'
import { colors, getReviveLogo } from './annotation-helpers'

export default class BigReviveLogoAnnotation extends ImageAnnotation {
	constructor(
		private position = { bottom: 0 },
		private relativeToHeight: number = 2500
	) {
		super(Array.from(arguments))
	}

	async annotate(image: ImageData): Promise<ImageData> {
		const rect = scaleDimensions(
			{
				width: 1729,
				height: 629
			},
			image.dimensions.height / this.relativeToHeight
		)

		const rectPosition = this.getPositioning(
			this.position as position,
			rect,
			image.dimensions
		)

		const file = await getReviveLogo(colors.light)

		const logoSize = scaleDimensions(
			{
				width: file.naturalWidth,
				height: file.naturalHeight
			},
			{ width: 1323 * (image.dimensions.height / this.relativeToHeight) }
		)

		const logoPaddings = {
			x: Math.abs(logoSize.width - rectPosition[2]) / 2,
			y: Math.abs(logoSize.height - rectPosition[3]) / 2
		}

		const [canvas, ctx] = await image.getCanvasWithImage()

		ctx.fillStyle = colors.dark
		ctx.fillRect(...rectPosition)

		ctx.drawImage(
			file,
			rectPosition[0] + logoPaddings.x,
			rectPosition[1] + logoPaddings.y,
			logoSize.width,
			logoSize.height
		)

		return new ImageData(
			canvas.toDataURL('image/jpeg', 1),
			image.dimensions
		)
	}
}
