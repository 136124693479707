import Vue from 'vue'
import i18n from './i18n'
import store from './store'
import App from './App.vue'
import router from './router'
import './plugins/font-awesome'
import './plugins/state-helpers'

Vue.config.productionTip = false
Vue.config.devtools = true

new Vue({
	router,
	i18n,
	store,
	data() {
		return {
			sorting: false,
			showingModal: 0
		}
	},
	render: h => h(App)
}).$mount('#app')
