import { Store } from 'vuex'
import ImageData from '@/types/ImageData'
import ImageAnnotation from '@/types/ImageAnnotation'

export default class ShippingPriceAnnotation extends ImageAnnotation {
	constructor(
		private fontSize: number = 102.5,
		private relativeToWidth: number = 2500
	) {
		super(Array.from(arguments))
	}

	annotate(image: ImageData, store: Store<any>): Promise<ImageData> {
		const factor = image.dimensions.width / this.relativeToWidth
		const shippingClass = store.getters.product.kind.shipping_class
		const y = 1175 + 90 - Math.abs(2500 - image.dimensions.height) / 2

		return (
			// @ts-ignore
			new FontFace(
				'lato',
				'url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXg.woff2)'
			)
				.load()
				// @ts-ignore
				.then(f => document.fonts.add(f))
				.then(() => image.getCanvasWithImage())
				.then(
					([canvas, ctx]: [
						HTMLCanvasElement,
						CanvasRenderingContext2D
					]) => {
						ctx.fillStyle = 'black'
						ctx.textAlign = 'start'
						ctx.font = `${this.fontSize * factor}px lato`
						const measurements = ctx.measureText(
							`${shippingClass}\u20AC`
						)
						ctx.fillText(
							`${shippingClass}\u20AC`,
							canvas.width / 4 - measurements.width / 2,
							y * factor
						)

						return new ImageData(
							canvas.toDataURL('image/jpeg', 1),
							image.dimensions
						)
					}
				)
		)
	}
}
