import Vue from 'vue'
import { merge } from 'lodash'
import { Module, mapState } from 'vuex'

Vue.use({
	install(v) {
		v.mixin({
			computed: {
				...mapState({
					$settings: (state: any) => state.settings.settings
				})
			}
		})
	}
})

export type OptionState = {
	settings: { [key: string]: any }
}

export default {
	namespaced: true,

	state: {
		settings: {
			gallery: {
				showObjectsInImage: true,
				imageDisplaySize: 254,
				boundingBoxPadding: 0.01
			},
			platforms: {
				padding: 0.04,
				imageSize: 100
			},
			product: {
				statusColors: {
					'5': 'bg-green-200'
				}
			}
		}
	},

	getters: {
		settings(state) {
			return state.settings
		},

		get(state) {
			return (
				setting: string | string[],
				defaultValue: any = null
			): { [key: string]: any } | any | null => {
				if (typeof setting === 'string') {
					setting = setting.split('.')
				}

				return (
					setting.reduce((scope, key) => {
						if (
							scope &&
							Object.prototype.hasOwnProperty.call(scope, key)
						) {
							return scope[key]
						}

						return null
					}, state.settings) || defaultValue
				)
			}
		}
	},

	mutations: {
		UPDATE_SETTING(state, option: { [key: string]: any }) {
			for (let key in option) {
				if (Object.prototype.hasOwnProperty.call(option, key)) {
					let value = option[key]

					if (key.includes('.')) {
						const keyParts = key.split('.')

						value = keyParts
							.slice(1)
							.reverse()
							.reduce((carry, item) => {
								const object: { [key: string]: any } = {}

								object[item] = carry

								return object
							}, value)

						key = keyParts[0]
					}

					const assigning: { [key: string]: any } = {}

					assigning[key] = value

					merge(state.settings, assigning)
				}
			}
		}
	}
} as Module<OptionState, unknown>
