import { NotificationLevel } from './definitions/misc'
import { NotificationType } from './definitions/NotificationType'

export default class Notification implements NotificationType {
	public id: string
	public message: string

	constructor(
		message: string | NotificationType | Error,
		public type: NotificationLevel = 'info',
		public closable: boolean = true,
		public timer: number = 0,
		public scrollUp: boolean = false
	) {
		this.id = `${Math.random()}_${Date.now()}`

		if (typeof message !== 'string') {
			if (typeof (message as Error).stack !== 'undefined') {
				;(message as NotificationType).closable = true
				;(message as NotificationType).timer = 0
				;(message as NotificationType).type = 'error'
			}

			this.type = (message as NotificationType).type || 'info'
			this.closable = (message as NotificationType).closable || true
			this.timer = (message as NotificationType).timer || 0
			message = message.message
		}

		this.message = message || `Unknown ${this.type}`
	}
}
