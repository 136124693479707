// Returns a callback that taps into the provided object. Useful for Promises.
export function tap<T>(cb: (item: T) => void): (t: T) => T {
	return (item: T) => _tap(item, cb)
}

export default function _tap<T>(
	item: T,
	cb: (item: T, ...args: any[]) => void,
	...args: any[]
): T {
	cb(item, ...args)

	return item
}
