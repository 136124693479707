import ImageData from './ImageData'
import { Store, ActionContext } from 'vuex'
import { ImageAnnotationInterface } from './definitions/ImageAnnotationInterface'

export default abstract class ImageAnnotation
	implements ImageAnnotationInterface {
	protected creationArgs: Array<any>

	abstract annotate(
		image: ImageData,
		store: Store<any> | ActionContext<any, any>
	): Promise<ImageData>

	constructor(args: any[]) {
		this.creationArgs = JSON.parse(JSON.stringify(args))
	}

	get args() {
		return this.creationArgs
	}

	protected normalizePositionAndCenter(
		position: position,
		imageDimensions: DimensionType,
		objectDimensions: DimensionType
	) {
		position = Object.assign(
			{},
			this.normalizePosition(position, imageDimensions)
		)

		if (
			typeof position.left === 'undefined' &&
			typeof position.right === 'undefined'
		) {
			position.left = position.right =
				(imageDimensions.width - objectDimensions.width) / 2
		}

		if (
			typeof position.top === 'undefined' &&
			typeof position.bottom === 'undefined'
		) {
			position.top = position.bottom =
				(imageDimensions.height - objectDimensions.height) / 2
		}

		if (typeof position.left === 'undefined') {
			position.left =
				imageDimensions.width -
				// @ts-ignore
				(position.right + objectDimensions.width)
		}

		if (typeof position.right === 'undefined') {
			position.right =
				imageDimensions.width - (position.left + objectDimensions.width)
		}

		if (typeof position.top === 'undefined') {
			position.top =
				imageDimensions.height -
				// @ts-ignore
				(position.bottom + objectDimensions.height)
		}

		if (typeof position.bottom === 'undefined') {
			position.bottom =
				imageDimensions.height -
				(position.top + objectDimensions.height)
		}

		return position
	}

	/**
	 * Returns pixel values for relative position parameters.
	 *
	 * @param position The position within the image
	 * @param dimensions The dimensions of the image
	 */
	protected normalizePosition(position: position, dimensions: DimensionType) {
		if (position.top) {
			position.top =
				position.top <= 1
					? position.top * dimensions.height
					: position.top
		}

		if (position.bottom) {
			position.bottom =
				position.bottom <= 1
					? position.bottom * dimensions.height
					: position.bottom
		}

		if (position.left) {
			position.left =
				position.left <= 1
					? position.left * dimensions.width
					: position.left
		}

		if (position.right) {
			position.right =
				position.right <= 1
					? position.right * dimensions.width
					: position.right
		}

		return position
	}

	protected getPositioning(
		position: position,
		size: DimensionType | undefined | null,
		image: DimensionType
	): [number, number, number, number] {
		const pos = this.normalizePositionAndCenter(
			position,
			image,
			size || { width: 0, height: 0 }
		) as wellDefinedPosition

		const dx = pos.left
		const dy = pos.top

		const dw = image.width - pos.right - pos.left
		const dh = image.height - pos.bottom - pos.top

		return [dx, dy, dw, dh]
	}
}
