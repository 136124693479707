












































































































import { Component, Vue } from 'vue-property-decorator'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'
import {
	faUserFriends,
	faUsers,
	faUser,
	faChair,
	faCouch
} from '@fortawesome/free-solid-svg-icons'

addFontAwesomeIcon(faUserFriends)
addFontAwesomeIcon(faUsers)
addFontAwesomeIcon(faUser)
addFontAwesomeIcon(faChair)
addFontAwesomeIcon(faCouch)

@Component
export default class Product extends Vue {
	get product() {
		return this.$store.state.product.product
	}
}
