import md5 from 'js-md5'
import { ImageRule } from '@/types/definitions/ImageRule'
import { StringifiedAnnotation } from '@/types/definitions/misc'

export function serializeImageAnnotations(
	annotations: StringifiedAnnotation[],
	prefix: string = '-'
) {
	return (
		prefix +
		(annotations
			.map(({ name, args }) => {
				let argsHash = ''

				if (args && args.length) {
					argsHash =
						',' + md5(JSON.stringify(args || [])).substr(0, 6)
				}

				return `${name}${argsHash}`
			})
			.map(a => a.toLowerCase().replace('annotation', ''))
			.join('-') || 'default')
	)
}

export function serializePadding(
	padding: optionalPadding,
	globalPadding: number,
	prefix: string = '-'
) {
	let string = prefix

	if (typeof padding.top !== 'undefined') {
		string += `t${padding.top}`
	} else {
		string += `t${globalPadding}`
	}

	if (typeof padding.right !== 'undefined') {
		string += `r${padding.right}`
	} else {
		string += `r${globalPadding}`
	}

	if (typeof padding.bottom !== 'undefined') {
		string += `b${padding.bottom}`
	} else {
		string += `b${globalPadding}`
	}

	if (typeof padding.left !== 'undefined') {
		string += `l${padding.left}`
	} else {
		string += `l${globalPadding}`
	}

	return string
}

export default function serializeImageRule(
	rule: ImageRule,
	globalPadding: number = 0,
	prefix: string = '-'
) {
	const annotations = serializeImageAnnotations(rule.overlays || [], '-')
	const padding = rule.allowFullPicture
		? 'full'
		: serializePadding(rule.padding || {}, globalPadding, '')

	return `${prefix}${padding}${annotations}`
}

export function serializeDimensions(
	dimensions: DimensionType,
	prefix: string = '-'
) {
	return `${prefix}${dimensions.width}x${dimensions.height}`
}
