import Platform from '@/types/Platform'

export default Platform.fromStorable({
	id: 'used-design',
	settings: {
		logo: require('@/assets/logo-used-design.png'),
		name: 'Used Design',
		format: {
			width: 2500,
			height: 2500
		},
		maxCount: 5,
		defaultOverlays: [{ name: 'WatermarkAnnotation', args: [true] }],
		setRules: {
			'0': [
				{
					category: ['special', 'title'],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					}
				}
			]
		},
		rules: {
			'0': [
				{
					category: ['special', 'title'],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					}
				}
			],
			'1': [
				{
					category: ['special', 'measurements'],
					allowFullPicture: true,
					padding: {
						top: 0.24,
						bottom: 0.31
					},
					overlays: [
						{ name: 'BrandLogoAnnotation' },
						{ name: 'MeasurementAnnotation', args: [true] }
					]
				}
			],
			'2': [
				{
					category: ['detail', 'damages'],
					allowFullPicture: true,
					allowFindNext: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				},
				{
					category: ['functions', null],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					},
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				},
				{
					category: ['alternate', null],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					},
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			],
			'3': [
				{
					category: ['detail', 'damages'],
					allowFullPicture: true,
					allowFindNext: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				},
				{
					category: ['special', 'materialDetail'],
					allowFullPicture: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			],
			'4': [
				{
					category: ['special', 'materialDetail'],
					allowFullPicture: true,
					allowFindNext: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				},
				{
					category: ['special', 'logo'],
					allowFullPicture: true,
					overlays: [{ name: 'WatermarkAnnotation', args: [true] }]
				}
			]
		}
	}
})
