export default function loadImage(
	src: string
): Promise<{
	img: HTMLImageElement
	canvas: HTMLCanvasElement
	ctx: CanvasRenderingContext2D
	dimensions: DimensionType
}> {
	return new Promise((resolve, reject) => {
		const img = new Image()

		img.addEventListener('error', (e: ErrorEvent) => {
			reject(`Could not load image ${src}: ${e.message}`)
		})

		img.addEventListener(
			'load',
			() => {
				const canvas = document.createElement('canvas')
				const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

				canvas.width = img.naturalWidth
				canvas.height = img.naturalHeight
				ctx.drawImage(img, 0, 0)

				const dimensions = {
					width: canvas.width,
					height: canvas.height
				}

				resolve({
					img,
					canvas,
					ctx,
					dimensions
				})
			},
			false
		)

		img.crossOrigin = 'anonymous'
		img.src = src
	})
}
