





























import { Component, Vue } from 'vue-property-decorator'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'

addFontAwesomeIcon(faLayerGroup)
addFontAwesomeIcon(faTimesCircle)

@Component
export default class BulkButton extends Vue {
	get show() {
		return (
			!this.$root.showingModal &&
			this.$store.getters['platforms/hasSelectedImages']
		)
	}

	unselect() {
		this.$store.getters['platforms/selectedImages'].forEach(
			i => (i.image.selected = false)
		)
	}
}
