import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
	FontAwesomeIcon,
	FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'
import {
	faChevronLeft,
	faChevronRight,
	faPhone,
	faEdit,
	faTrash,
	faTrashRestore,
	faQuoteRight,
	faSave,
	faTimes,
	faPlusCircle,
	faLock,
	faUnlockAlt,
	faCaretDown,
	faCheck
} from '@fortawesome/free-solid-svg-icons'
import {
	faEnvelope,
	faCircle,
	faPlusSquare
} from '@fortawesome/free-regular-svg-icons'

library.add(
	faChevronLeft,
	faChevronRight,
	faEnvelope,
	faPhone,
	faCircle,
	faEdit,
	faTrash,
	faTrashRestore,
	faQuoteRight,
	faSave,
	faTimes,
	faPlusCircle,
	faCaretDown,
	faPlusSquare,
	faLock,
	faUnlockAlt,
	faCheck
)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-stack', FontAwesomeLayers)
