

















































































import md5 from 'js-md5'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import { debounce } from 'lodash'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Component, Vue, Watch } from 'vue-property-decorator'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'

addFontAwesomeIcon(faSpinner)

@Component({
	computed: {
		displaySize: {
			get() {
				return this.$store.state.settings.settings.gallery
					.imageDisplaySize
			},
			set: debounce(function (v) {
				this.$store.commit('settings/UPDATE_SETTING', {
					'gallery.imageDisplaySize': v
				})
			}, 200)
		},
		boundingBoxPadding: {
			get() {
				return (
					this.$store.state.settings.settings.gallery
						.boundingBoxPadding * 100
				)
			},
			set: debounce(function (v) {
				this.$store.commit('settings/UPDATE_SETTING', {
					'gallery.boundingBoxPadding': v / 100
				})
			}, 200)
		}
	}
})
export default class ImageGalleryHeader extends Vue {
	protected showOptions: boolean = false
	protected downloading: boolean = false

	get product() {
		return this.$store.getters['product']
	}

	download() {
		if (this.downloading) return

		this.downloading = true

		const zip = new JSZip()
		const fileName = `${this.product.sku}-originals`

		this.getOriginalImages()
			.then(images =>
				images.forEach(
					(i, ix) =>
						i &&
						zip.file(
							`${i.category}-${i.subCategory || 'default'}-${
								i.order
							}.jpg`,
							i.image.blob
						)
				)
			)
			.then(() => zip.generateAsync({ type: 'blob' }))
			.then(file =>
				saveAs(
					file,
					`${fileName}-${md5(
						new Date().getMilliseconds().toString()
					).substr(0, 8)}.zip`
				)
			)
			.catch(this.$handleError)
			.finally(() => (this.downloading = false))
	}

	private getOriginalImages() {
		return Promise.resolve(this.$store.state.images.images)
	}
}
