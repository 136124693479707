import AttributeArray from './AttributeArray'

type PropertyName = string | number | symbol
type ModelBarebone = { attributes: AttributeArray }

const ModelProxy: ProxyHandler<Model<any>> = {
	has(target: ModelBarebone, p: PropertyName) {
		const accessor = Object.getOwnPropertyDescriptor(target, p)

		if (accessor && accessor.get) {
			return true
		}

		return (
			target.attributes &&
			Object.prototype.hasOwnProperty.call(target.attributes, p)
		)
	},

	get(target: ModelBarebone, p: PropertyName) {
		const accessor = Object.getOwnPropertyDescriptor(target, p)

		if (accessor && accessor.get) {
			return accessor.get()
		}

		if (
			typeof p === 'symbol' ||
			(!(p in target) && !('attributes' in target))
		) {
			return undefined
		}

		if (p === 'constructor') {
			return Model
		}

		if (p === 'attributes') {
			return target.attributes
		}

		return target.attributes && target.attributes[p]
	},

	set(target: ModelBarebone, p: PropertyName, value: any) {
		const mutator = Object.getOwnPropertyDescriptor(target, p)

		if (mutator && mutator.set) {
			return mutator.set(value)
		}

		if (typeof p === 'symbol') {
			return false
		}

		return (target.attributes[p] = value)
	},

	deleteProperty(target: ModelBarebone, p: PropertyName) {
		if (typeof p === 'symbol') {
			p = p.toString()
		}

		return delete target.attributes[p]
	}
}

export default class Model<T extends AttributeArray> implements ModelBarebone {
	public attributes: T

	constructor(attributes?: T) {
		this.attributes = <T>attributes || {}

		return new Proxy(this, ModelProxy as ProxyHandler<Model<T>>)
	}
}
