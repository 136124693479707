






import { Component, Vue } from 'vue-property-decorator'

@Component
export default class App extends Vue {
	mounted() {
		window.addEventListener('beforeunload', (e = window.event) => {
			if (this.$route.fullPath !== '/login') {
				if (e) {
					e.returnValue = 'Sure?'
				}

				return 'Sure?'
			}
		})
	}
}
