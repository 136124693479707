import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/state/auth'
import images from '@/state/images'
import product from '@/state/product'
import VuexPersist from 'vuex-persist'
import settings from '@/state/settings'
import platforms from '@/state/platforms'
import notifications from '@/state/notifications'

Vue.use(Vuex)

const settingsStorage = new VuexPersist({
	key: 'image-api-settings',
	modules: ['settings']
})

const authStorage = new VuexPersist({
	key: 'image-api-auth',
	modules: ['auth']
})

export default new Vuex.Store({
	plugins: [settingsStorage.plugin, authStorage.plugin],

	modules: {
		notifications,
		images,
		settings,
		platforms,
		product,
		auth
	}
})
