import Vue from 'vue'
import VueI18n from 'vue-i18n'
//import de from '../../backend/resources/lang/de.json'

Vue.use(VueI18n)

export default new VueI18n({
	locale: 'de',
	messages: { /*de*/ de: {} },
	formatFallbackMessages: true,
	silentTranslationWarn: true || process.env.APP_ENV === 'production',
	silentFallbackWarn: true || process.env.APP_ENV === 'production'
})
