import ImageData from '@/types/ImageData'
import ImageAnnotation from '@/types/ImageAnnotation'

export default class UsedBannerAnnotation extends ImageAnnotation {
	constructor(
		private text: string = 'Used',
		private position: position = { top: 68, right: 0 },
		private fontSize: number = 111,
		private fontFamily: string = 'gotham-light',
		private padding: padding = { top: 67, right: 53, bottom: 67, left: 53 }
	) {
		super(Array.from(arguments))
	}

	annotate(image: ImageData): Promise<ImageData> {
		return (
			// @ts-ignore
			new FontFace('gotham-light', 'url(/fonts/gotham-light.woff2)')
				.load()
				// @ts-ignore
				.then(f => document.fonts.add(f))
				.then(() => image.getCanvasWithImage())
				.then(
					([canvas, ctx]: [
						HTMLCanvasElement,
						CanvasRenderingContext2D
					]) => {
						const position = this.normalizePosition(this.position, {
							width: canvas.width,
							height: canvas.height
						}) as wellDefinedPosition

						ctx.font = `${this.fontSize}px ${this.fontFamily}`

						const measurements = ctx.measureText(this.text)

						ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'
						ctx.fillRect(
							canvas.width -
								this.padding.right -
								measurements.width -
								this.padding.left,
							position.top,
							this.padding.left +
								measurements.width +
								this.padding.right,
							this.padding.top +
								this.fontSize +
								this.padding.bottom
						)

						ctx.fillStyle = 'white'
						ctx.textBaseline = 'top'
						ctx.fillText(
							this.text,
							canvas.width -
								this.padding.right -
								measurements.width,
							position.top + this.padding.top
						)

						return new ImageData(
							canvas.toDataURL('image/jpeg', 1),
							image.dimensions
						)
					}
				)
		)
	}
}
