

























import PromisedImage from './PromisedImage.vue'
import ProductImage from '@/types/product-image'
import scaleDimensions from '@/helpers/scale-dimensions'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

addFontAwesomeIcon(faCheck)

@Component({ components: { PromisedImage } })
export default class ImagePickerItem extends Vue {
	public src: Promise<ImageData> = null

	@Prop({ type: Boolean, required: true })
	readonly selected: boolean

	@Prop({ type: String, required: true })
	readonly image: string

	created() {
		this.resetSource()
	}

	byId(id: string) {
		return this.$store.getters['images/byId'](id)
	}

	@Watch('image')
	resetSource() {
		this.src = this.$store.dispatch('images/GET_IMAGE', {
			id: this.image,
			type: 'original',
			dimensions: this.byId(this.image).image.dimensions
		})
	}
}
