














































































import Modal from './Modal.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { Modal } })
export default class skuPromptModal extends Vue {
	private resolve: (number) => any = () => null
	private reject: () => any = () => null

	protected sku: number = null
	protected loadImages: boolean = true
	protected beforeLoadImages: boolean = true
	protected clearImages: boolean = true
	protected beforeClearImages: boolean = true

	public open(sku = null) {
		this.sku = sku

		return new Promise((resolve, reject) => {
			this.resolve = resolve
			this.reject = reject

			this.$refs.base.open()

			this.$nextTick().then(() => this.$refs.input.focus())
		})
	}

	public close() {
		this.$refs.base.close()
	}

	protected select(e: Event) {
		let result = {
			sku: this.sku,
			loadImages: this.loadImages,
			clearImages: this.clearImages
		}

		if (e.ctrlKey || !this.loadImages) {
			result.loadImages = false
		}

		if (e.shiftKey || !this.clearImages) {
			result.clearImages = false
		}

		this.resolve(result)
		this.close()
	}

	protected keydown(e) {
		if (e.key === 'Control') {
			this.beforeLoadImages = this.loadImages
			this.loadImages = !this.loadImages
		}

		if (e.key === 'Shift') {
			this.beforeClearImages = this.clearImages
			this.clearImages = !this.clearImages
		}
	}

	protected keyup(e) {
		if (e.key === 'Control') {
			this.loadImages = this.beforeLoadImages
		}

		if (e.key === 'Shift') {
			this.clearImages = this.beforeClearImages
		}
	}
}
