type Time = {
	message: string
	time: number
}

class Timer {
	protected _start: number
	protected _times: Time[] = []

	public constructor(public name: string) {
		this._start = new Date().getTime()
	}

	get start() {
		return this._start
	}

	get times() {
		return this._times
	}

	public stop(message: string) {
		const time = {
			message,
			time: new Date().getTime() - this._start
		}

		this._times.push(time)

		return time
	}

	public get(messageOrIndex: string | number, all: boolean = false) {
		if (typeof messageOrIndex === 'number') {
			return this.getIndex(messageOrIndex)
		}

		return this._times[all ? 'filter' : 'find'](
			t => t.message === messageOrIndex
		)
	}

	public getIndex(index: number) {
		return this._times[index]
	}
}

const timers = {} as { [key: string]: Timer }

export default function time(
	name: string,
	message?: string,
	forceNew: boolean = false
) {
	if (typeof timers[name] === 'undefined' || forceNew) {
		timers[name] = new Timer(name)
	}

	if (message) {
		return timers[name].stop(message)
	}
}

export function getTimer(name: string) {
	return timers[name]
}

export function timeAndLog(
	name: string,
	message: string,
	forceNew: boolean = false
) {
	const timed = time(name, message, forceNew) as Time

	// eslint-disable-next-line no-console
	console.log(
		`Timer [${name}]: ${message} @${Math.round(timed.time / 10) / 100}s`
	)
}

export function tapTimeAndLog(
	name: string,
	message: string,
	forceNew: boolean = false
) {
	return (i: any) => {
		timeAndLog(name, message, forceNew)

		return i
	}
}
