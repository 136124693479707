export default class Dimensions implements DimensionType {
	private _width: number
	private _height: number

	constructor(dimensions: number | DimensionType, height?: number) {
		if (typeof dimensions !== 'object' && typeof height === 'undefined') {
			throw 'Height must be defined if the first parameter is not a DimensionType.'
		}

		if (typeof dimensions === 'object') {
			this._width = dimensions.width
			this._height = dimensions.height
		} else {
			this._width = dimensions
			this._height = height as number
		}
	}

	get width() {
		return this._width
	}

	get height() {
		return this._height
	}

	get size() {
		return this.width * this.height
	}

	oneSideSmaller(dimension: DimensionType) {
		return this.width < dimension.width || this.height < dimension.height
	}
}
