import Area from './Area'
import Dimensions from './Dimension'
import loadImage from '@/helpers/load-image'
import { ImageDataInterface } from './definitions/ImageDataInterface'

export default class ImageData implements ImageDataInterface {
	private _src: string
	private _dimensions: Dimensions
	private _isLink: boolean = false

	constructor(src: string, dimensions?: DimensionType, isLink?: boolean) {
		this._src = src

		if (isLink) {
			this._isLink = isLink
		}

		if (dimensions) {
			this._dimensions = new Dimensions(dimensions)
		} else {
			this._dimensions = new Dimensions(0, 0)
		}
	}

	get src() {
		return this._src
	}

	get isLink() {
		return this._isLink
	}

	get dimensions() {
		return this._dimensions
	}

	get width() {
		return this.dimensions.width
	}

	get height() {
		return this.dimensions.height
	}

	get base64() {
		if (this._isLink) {
			return ''
		}

		return this.src.substr(this.src.indexOf(',') + 1)
	}

	get blob() {
		return fetch(this.src).then(r => r.blob())
	}

	load(force: boolean = false): Promise<ImageData> {
		if (this._isLink || force) {
			return loadImage(this._src).then(({ img, canvas }) => {
				if (this._isLink) {
					this._src = canvas.toDataURL('image/jpeg', 1)
				}

				this._dimensions = new Dimensions({
					width: img.width,
					height: img.height
				})

				this._isLink = false

				return Promise.resolve(this)
			})
		}

		return Promise.resolve(this)
	}

	public section(area: Area) {
		return this.getImageCanvas().then(([canvas, img, ctx]) => {
			area = area.integrate({
				width: img.naturalWidth,
				height: img.naturalHeight
			})

			canvas.width = area.width
			canvas.height = area.height

			ctx.drawImage(
				img,
				area.start.x,
				area.start.y,
				area.width,
				area.height,
				0,
				0,
				area.width,
				area.height
			)

			return new ImageData(
				canvas.toDataURL('image/jpeg', 1),
				area.dimensions
			)
		})
	}

	public scale(
		factor: number | Partial<DimensionType>,
		allowUpscale: boolean = false,
		quality = 1
	): Promise<ImageData> {
		const dimensions = this._dimensions as DimensionType

		if (typeof factor === 'object') {
			if (factor.width) {
				factor = factor.width / dimensions.width
			} else if (factor.height) {
				factor = factor.height / dimensions.height
			} else {
				throw 'Factor must at least contain one of: width, height'
			}
		}

		if (factor > 1 && !allowUpscale) {
			return Promise.resolve(new ImageData(this._src, dimensions))
		}

		return this.resize(
			Math.round(dimensions.width * factor),
			Math.round(dimensions.height * factor),
			quality
		)
	}

	public resize(
		width: number,
		height: number,
		quality = 1
	): Promise<ImageData> {
		return this.getImageCanvas().then(([canvas, img, ctx]) => {
			canvas.width = width
			canvas.height = height

			ctx.imageSmoothingEnabled = true
			ctx.imageSmoothingQuality = 'high'
			ctx.drawImage(
				img,
				0,
				0,
				img.naturalWidth,
				img.naturalHeight,
				0,
				0,
				width,
				height
			)

			return new ImageData(canvas.toDataURL('image/jpeg', quality), {
				width,
				height
			})
		})
	}

	private getBlankCanvas(): [HTMLCanvasElement, CanvasRenderingContext2D] {
		const canvas: any = document.createElement('canvas')

		return [canvas, canvas.getContext('2d')]
	}

	private getImageCanvas(): Promise<
		[HTMLCanvasElement, HTMLImageElement, CanvasRenderingContext2D]
	> {
		return new Promise((resolve, reject) => {
			const img = new Image()

			img.addEventListener('load', () => {
				const [canvas, ctx] = this.getBlankCanvas()

				resolve([canvas, img, ctx])
			})

			img.addEventListener('error', (e: ErrorEvent) => reject(e))

			img.crossOrigin = 'anonymous'
			img.src = this.src
		})
	}

	public getCanvasWithImage(): Promise<
		[HTMLCanvasElement, CanvasRenderingContext2D, DimensionType]
	> {
		return this.getImageCanvas().then(([canvas, img, ctx]) => {
			const dimensions = this.dimensions as DimensionType

			canvas.width = dimensions.width
			canvas.height = dimensions.height

			ctx.drawImage(img, 0, 0)

			return [canvas, ctx, dimensions]
		})
	}

	toString() {
		return this.src
	}
}
