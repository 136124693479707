import Platform from '@/types/Platform'

export default Platform.fromStorable({
	id: '1stdibs',
	settings: {
		logo: require('@/assets/logo-1stdibs.png'),
		name: '1stdibs',
		format: {
			width: 2500,
			height: 2500
		},
		maxCount: 20,
		setRules: {
			'0': [
				{
					category: ['special', 'title'],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					}
				}
			]
		},
		rules: {
			'0': [
				{
					category: ['special', 'title'],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					}
				}
			],
			'1': [
				{
					category: ['special', 'measurements'],
					allowFullPicture: true,
					padding: {
						top: 0.24,
						bottom: 0.31
					},
					overlays: [
						{ name: 'MeasurementAnnotation', args: [false, 'en'] }
					]
				}
			],
			'2': [
				{
					category: ['functions', null],
					allowFullPicture: true,
					padding: {
						top: 0.2,
						bottom: 0.2
					}
				}
			],
			'3': [
				{
					category: ['special', 'materialDetail'],
					allowFullPicture: true
				}
			],
			'4': [
				{
					category: ['detail', null],
					allowFullPicture: true
				}
			],
			'5': [
				{
					category: ['detail', 'damages'],
					allowFullPicture: true
				}
			],
			'6': [
				{
					category: ['detail', 'damages'],
					allowFullPicture: true
				}
			],
			'7': [
				{
					category: ['special', 'colors'],
					allowFullPicture: true
				}
			],
			'8': [
				{
					category: ['special', 'logo'],
					allowFullPicture: true
				}
			]
		},
		fillRule: [
			{
				category: ['alternate', null],
				padding: {
					top: 0.2,
					bottom: 0.2
				}
			}
		]
	}
})
