import ImageData from '@/types/ImageData'
import ImageAnnotation from '@/types/ImageAnnotation'
import scaleDimensions from '@/helpers/scale-dimensions'
import {
	colors,
	getReviveLogo,
	getReviveLogoWithSlogan
} from './annotation-helpers'

export default class WatermarkAnnotation extends ImageAnnotation {
	private size: DimensionType

	constructor(private withSlogan = false, private relativeToWidth = 2500) {
		super(Array.from(arguments))

		this.size = withSlogan
			? { width: 742, height: 352 }
			: { width: 905, height: 250 }
	}

	async annotate(image: ImageData): Promise<ImageData> {
		const size = scaleDimensions(
			this.size,
			image.width / this.relativeToWidth
		)

		const [canvas, ctx] = await image.getCanvasWithImage()

		const file = this.withSlogan
			? await getReviveLogoWithSlogan(colors.dark, colors.light)
			: await getReviveLogo(colors.dark)

		ctx.drawImage(
			file,
			...this.getPositioning(
				{ bottom: 106, right: 106 },
				size,
				image.dimensions
			)
		)

		return new ImageData(
			canvas.toDataURL('image/jpeg', 1),
			image.dimensions
		)
	}
}
