import Area from './Area'
import Point from './Point'
import ImageData from './ImageData'
import { tap } from '@/helpers/tap'
import Dimensions from './Dimension'
import Notification from './Notification'
import PromiseWorker from '@/helpers/worker'
import randomString from '@/helpers/random-string'
import { ImageRule } from './definitions/ImageRule'
import { timeAndLog, tapTimeAndLog } from '@/helpers/timer'
import VisionApiBaseImage from './definitions/VisionApiBaseImage'
import LocalizedObjectAnnotation from './LocalizedObjectAnnotation'
import { ImageDataInterface } from './definitions/ImageDataInterface'
import { ProductImageInterface } from './definitions/ProductImageInterface'

type BoundingBoxPromiseWorker = PromiseWorker<
	VisionApiBaseImage,
	BoundingBoxWorkerResult
>

export default class ProductImage implements ProductImageInterface {
	private _annotating: boolean = false

	protected _id: ProductImageInterface['id']
	protected _order: ProductImageInterface['order'] = 0
	protected _name: ProductImageInterface['name']
	protected _image: ProductImageInterface['image']

	protected _associatedProduct: ProductImageInterface['associatedProduct'] =
		''

	protected _objects: ProductImageInterface['objects'] = []
	protected _categories: ProductImageInterface['categories'] = []

	protected _prohibitDelete: ProductImageInterface['prohibitDelete'] = false
	protected _prohibitResetCategories: ProductImageInterface['prohibitResetCategories'] = false

	constructor(id: string, src: string, name: string) {
		this._id = id
		this._name = name
		this._image = new ImageData(src)
	}

	get prohibitDelete() {
		return this._prohibitDelete
	}

	get prohibitResetCategories() {
		return this._prohibitResetCategories
	}

	get dimensions() {
		return this._image.dimensions
	}

	get order() {
		return this._order
	}

	get annotating() {
		return this._annotating
	}

	get id() {
		return this._id
	}

	get name() {
		return this._name
	}

	get image() {
		return this._image
	}

	get objects() {
		return this._objects
	}

	set objects(v) {
		this._objects = v.map(o => {
			if (!o.hasOwnProperty('toArea')) {
				return LocalizedObjectAnnotation.rehydrate(o)
			}

			return o
		})
	}

	get associatedProduct() {
		return this._associatedProduct
	}

	set associatedProduct(v) {
		this._associatedProduct = v
	}

	get categories() {
		return this._categories
	}

	set categories(v) {
		this._categories = v
	}

	get filename(): string {
		return this._name.split('/').pop() || ''
	}

	get filenameWithoutExtension() {
		return this.filename.split('.').slice(0, -1).join('.')
	}

	get extension() {
		return this.filename.split('.').pop() || ''
	}

	get objectAreas() {
		return this.objects.map(object =>
			new LocalizedObjectAnnotation(object).toArea(
				this.image.dimensions as DimensionType
			)
		)
	}

	get completeObjectCoverageArea() {
		return Area.getCombinedArea(...this.objectAreas)
	}

	get completeObjectCoverage() {
		if (!this._image) {
			return 0
		}

		const { width, height } = this.image.dimensions as DimensionType

		return this.completeObjectCoverageArea / (width * height)
	}

	get autoCategory() {
		return this.completeObjectCoverage > 0.5 ||
			(this.completeObjectCoverage === 0 && this.objects.length)
			? 'detail'
			: 'alternate'
	}

	get category() {
		if (this.categories.length) {
			return this.categories[0]
		}

		return this.autoCategory
	}

	get subCategory() {
		if (this.categories.length <= 1) {
			return null
		}

		return this.categories[1]
	}

	static fromFile(file: {
		contents: string
		name: string
		type: string
	}): ProductImage {
		const fileName = file.name
			.substr(0, file.name.lastIndexOf('.'))
			.substr(0, 15)
		const id = fileName + randomString(5)

		return new ProductImage(id, file.contents, file.name)
	}

	fetchAnnotations(): Promise<{
		id: string
		objects: LocalizedObjectAnnotationType[]
	}> {
		this._annotating = true

		timeAndLog(this.name, 'start loading', true)

		return this.image
			.load(this.image.width === 0)
			.then(tapTimeAndLog(this.name, 'stop loading'))
			.then(tapTimeAndLog(this.name, 'start scaling'))
			.then(i => i.scale({ width: 640 }))
			.then(tapTimeAndLog(this.name, 'stop scaling'))
			.then(tapTimeAndLog(this.name, 'start sending to Google'))
			.then(image =>
				(PromiseWorker.once() as BoundingBoxPromiseWorker).run({
					name: this.name,
					id: this.id,
					base64: image.base64
				})
			)
			.then(tapTimeAndLog(this.name, 'stop sending to Google'))
			.finally(() => (this._annotating = false))
	}

	getBoundingBox(enhancementFactor: number): Area {
		const { width, height } = this.dimensions as DimensionType

		const boundingBox = new Area(new Point(width, height), new Point(0, 0))

		if (!this.objects.length) {
			return boundingBox
		}

		return this.objects
			.reduce((bbox: Area, object: LocalizedObjectAnnotation) => {
				const area = object.toArea({ width, height })

				bbox.start = new Point(
					Math.min(bbox.start.x, area.start.x, area.end.x),
					Math.min(bbox.start.y, area.start.y, area.end.y)
				)

				bbox.end = new Point(
					Math.max(bbox.start.x, area.start.x, area.end.x),
					Math.max(bbox.start.y, area.start.y, area.end.y)
				)

				return bbox
			}, boundingBox)
			.enhance(enhancementFactor, this.image.dimensions as DimensionType)
	}

	generateBaseImage(
		rule: ImageRule,
		targetDimensions: Dimensions,
		globalPadding: number,
		enhancementFactor: number
	) {
		const dimensions = this.dimensions as Dimensions

		if (dimensions.oneSideSmaller(targetDimensions)) {
			return Promise.reject(
				// eslint-disable-next-line max-len
				`${this.name}: Image resolution is too small. (${dimensions.width} x ${dimensions.height}). The platform needs at least ${targetDimensions.width} x ${targetDimensions.height}`
			)
		}

		if (dimensions.size < 15000000) {
			return Promise.reject(
				new Notification(
					// eslint-disable-next-line max-len
					`${this.name}: The base image resolution is small (${dimensions.width} x ${dimensions.height}), which means that it can come to problems when cutting the image.<br /> If possible, please try to take an image with a higher resolution.`,
					'warning'
				)
			)
		}

		const boundingBox = this.getBoundingBox(enhancementFactor)
		const padding = this.getPadding(
			targetDimensions,
			globalPadding,
			rule.padding
		)
		const topBottomSum = padding.top + padding.bottom
		const maxCoverage =
			topBottomSum + (padding.left + padding.right) * (1 - topBottomSum)

		if (
			boundingBox.area === 0 ||
			this.completeObjectCoverage > maxCoverage
		) {
			if (rule.allowFullPicture) {
				return this.getFullImage(targetDimensions)
			} else {
				return Promise.reject(`${this.name}: Object is too small`)
			}
		}

		let area = this.getSectionArea(targetDimensions, boundingBox, padding)

		// Enlarge section area as to meet target dimension min specs
		if (new Dimensions(area).oneSideSmaller(targetDimensions)) {
			area = area.enlarge(
				Math.max(
					targetDimensions.width / area.width - 1,
					targetDimensions.height / area.height - 1
				)
			)
		}

		const errorCorrectionThreshold = 0.05

		if (area.end.y > dimensions.height || area.start.y < 0) {
			if (rule.allowFullPicture) {
				return this.getFullImage(targetDimensions)
			}

			if (
				!(area = area.correct(
					dimensions,
					errorCorrectionThreshold
				) as Area)
			) {
				return Promise.reject(
					`${this.name}: the final image is out of bounds`
				)
			} else {
				// eslint-disable-next-line no-console
				console.info(`Corrected image ${this.name}`)
			}
		}

		return this.image.section(area).then(
			tap(img => {
				if (
					(img.dimensions as Dimensions).oneSideSmaller(
						targetDimensions
					)
				) {
					// eslint-disable-next-line max-len
					throw `Could not successfully generate target image for ${this.name}. Target dimensions would be too small. This should not happen.`
				}
			})
		)
	}

	protected getFullImage(targetDimensions: DimensionType) {
		return this.image
			.scale({ height: targetDimensions.height })
			.then(image => {
				const xDiff =
					((image.width as number) - targetDimensions.width) / 2

				return image.section(
					new Area(
						new Point(xDiff, 0),
						new Point(
							targetDimensions.width + xDiff,
							targetDimensions.height
						)
					)
				)
			})
	}

	protected getPadding(
		targetDimensions: Dimensions,
		global: number,
		rule?: optionalPadding
	): padding {
		const full: padding = Object.assign(
			{
				left: 0,
				top: 0,
				right: 0,
				bottom: 0
			},
			rule || {}
		)

		const keyDimensionMap: {
			[key in keyof padding]: 'width' | 'height'
		} = {
			left: 'width',
			right: 'width',
			top: 'height',
			bottom: 'height'
		}
		;(Object.keys(full) as (keyof padding)[]).forEach(key => {
			let value = full[key]

			if (value > 100) {
				value = value / targetDimensions[keyDimensionMap[key]]
			}

			full[key] = Math.max(value, global)
		})

		return full
	}

	protected getSectionArea(
		finalDimensions: DimensionType,
		boundingBox: Area,
		padding: padding
	) {
		const xPadding = padding.left + padding.right
		const objectWidthRatio = 1 - xPadding
		const imgWidth = boundingBox.width / objectWidthRatio

		const yPadding = padding.top + padding.bottom
		const objectHeightRatio = 1 - yPadding
		const imgHeight = boundingBox.height / objectHeightRatio

		const scale = Math.max(
			imgWidth / finalDimensions.width,
			imgHeight / finalDimensions.height
		)

		const finalDimensionsSector = {
			width: scale * finalDimensions.width,
			height: scale * finalDimensions.height
		}

		const finalDimensionsSectorDiff = {
			width: finalDimensionsSector.width - boundingBox.width,
			height: finalDimensionsSector.height - boundingBox.height
		}

		const start = new Point(
			boundingBox.start.x - finalDimensionsSectorDiff.width / 2,
			boundingBox.start.y - finalDimensionsSectorDiff.height / 2
		)

		const end = new Point(
			boundingBox.end.x + finalDimensionsSectorDiff.width / 2,
			boundingBox.end.y + finalDimensionsSectorDiff.height / 2
		)

		return new Area(start, end)
	}

	generateOriginalImage() {
		return new ImageData(this.image.src, this.image.dimensions)
	}

	generateImage(type: string, args: any[] = []): Promise<ImageDataInterface> {
		const baseType = `generate${type.charAt(0).toUpperCase()}${type
			.substr(1)
			.toLowerCase()}Image`

		if (typeof (<any>this)[baseType] !== 'function') {
			throw new Error(`Cannot find generator for image type ${type}`)
		}

		return Promise.resolve((<any>this)[baseType](...args))
	}
}
