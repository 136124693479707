















































































































import { debounce } from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
	computed: {
		imageSize: {
			get() {
				return this.$store.state.settings.settings.platforms.imageSize
			},
			set: debounce(function(v) {
				this.$store.commit('settings/UPDATE_SETTING', {
					'platforms.imageSize': v
				})
			}, 200)
		}
	}
})
export default class PlatformsHeader extends Vue {
	protected showOptions: boolean = false

	get padding() {
		return this.$settings.platforms.padding * 100
	}

	set padding(v) {
		return this.$store.commit('settings/UPDATE_SETTING', {
			'platforms.padding': v / 100
		})
	}
}
