

















import scaleDimensions from '@/helpers/scale-dimensions'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { Component, Vue, Prop } from 'vue-property-decorator'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'

addFontAwesomeIcon(faImage)

@Component
export default class PlaceholderImage extends Vue {
	@Prop({ type: Object, required: true })
	readonly platform!: Platform

	protected dimensions: dimensions = { width: 0, height: 0 }

	created() {
		this.dimensions = scaleDimensions(this.platform.settings.format, {
			width: this.$settings.platforms.imageSize
		})
	}
}
