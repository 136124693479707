import Area from '@/types/Area'
import Point from '@/types/Point'
import ProductImage from '@/types/ProductImage'
import { ImageRule } from '@/types/definitions/ImageRule'

export default class PermanentImage extends ProductImage {
	_categories = ['permanent', null] as Category

	get disallowImageEdit() {
		return true
	}

	generateBaseImage(rule: ImageRule, targetDimensions: DimensionType) {
		rule.overlays = rule.overlays || []
		rule.allowFullPicture = true

		if (this.id === 'PERM_SHIPPING') {
			if (!rule.overlays.length) {
				rule.overlays.push({ name: 'ShippingPriceAnnotation' })
			}
		}

		if (targetDimensions.height === 2500) {
			return Promise.resolve(this.image)
		}

		// Cut around center
		return this.image.section(
			new Area(new Point(0, 312.5), new Point(2500, 2187.5))
		)
	}
}
