import Vue from 'vue'
import Notification from '@/types/Notification'
import { NotificationType } from '@/types/definitions/NotificationType'

Vue.use(v => {
	v.prototype.$commit = function(mutation: string) {
		return (payload: any) => {
			this.$store.commit(mutation, payload)

			return payload
		}
	}

	v.prototype.$dispatch = function(action: string) {
		return (payload: any) => this.$store.dispatch(action, payload)
	}

	v.prototype.$handleError = function(e: string | Error | NotificationType) {
		if (!e) {
			return
		}

		if (e instanceof Event) {
			this &&
				this.$store.dispatch(
					'notifications/ADD',
					new Notification(
						`
							An unknown error occurred.
							Please inspect the console
							(<key>[F12]</key> or
							<key>[Ctrl]</key> / <key>[Cmd]</key>
							+ <key>[Shift]</key> + <key>[I]</key>).
						`,
						'error'
					)
				)

			return
		}

		if (e instanceof Notification) {
			return this.$store.dispatch('notifications/ADD', e)
		}

		console.error(e) // eslint-disable-line no-console

		this &&
			this.$store.dispatch(
				'notifications/ADD',
				new Notification(e, 'error')
			)

		return Promise.reject()
	}
})
