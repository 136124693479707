import WatermarkAnnotation from './watermark'
import BrandLogoAnnotation from './brand-logo'
import UsedBannerAnnotation from './used-banner'
import MeasurementAnnotation from './measurements'
import ShippingPriceAnnotation from './shipping-price'
import BigReviveLogoAnnotation from './big-revive-logo'
import { StringifiedAnnotation } from '@/types/definitions/misc'
import { ImageAnnotationInterface } from '@/types/definitions/ImageAnnotationInterface'

export const annotationMap: {
	[key: string]: Constructable<ImageAnnotationInterface>
} = {
	// @ts-ignore
	WatermarkAnnotation: WatermarkAnnotation,
	// @ts-ignore
	BrandLogoAnnotation: BrandLogoAnnotation,
	// @ts-ignore
	BigReviveLogoAnnotation: BigReviveLogoAnnotation,
	// @ts-ignore
	UsedBannerAnnotation: UsedBannerAnnotation,
	// @ts-ignore
	ShippingPriceAnnotation: ShippingPriceAnnotation,
	// @ts-ignore
	MeasurementAnnotation: MeasurementAnnotation
}

export function instantiateAnnotations(
	annotations?: StringifiedAnnotation[]
): ImageAnnotationInterface[] {
	return (annotations || []).map(annotation => {
		return new annotationMap[annotation.name](
			...Object.values(annotation.args || [])
		)
	})
}
