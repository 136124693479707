export default function scaleDimensions(
	dimensions: DimensionType,
	scale: number | { width: number } | { height: number }
): DimensionType {
	if (typeof scale === 'object') {
		if (Object.prototype.hasOwnProperty.call(scale, 'width')) {
			scale = (scale as { width: any }).width / dimensions.width
		} else {
			scale = (scale as { height: any }).height / dimensions.height
		}
	}

	return {
		width: Math.floor(dimensions.width * scale),
		height: Math.floor(dimensions.height * scale)
	}
}

export function scaleDimensionsMax(
	dimensions: DimensionType,
	maxDimensions: DimensionType
) {
	return scaleDimensions(
		dimensions,
		Math.min(
			maxDimensions.width / dimensions.width,
			maxDimensions.height / dimensions.height
		)
	)
}
