





































import Modal from './Modal.vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ components: { Modal } })
export default class ConfirmModal extends Vue {
	@Prop({ type: String })
	readonly confirmText: string

	@Prop({ type: String })
	readonly cancelText: string

	protected resolveCallback: Function = () => ({})
	protected rejectCallback: Function = () => ({})

	openModal() {
		return new Promise((resolve, reject) => {
			this.resolveCallback = resolve
			this.rejectCallback = reject

			this.$refs.base.open()
		})
	}

	closeModal(reject = true) {
		if (reject) {
			this.rejectCallback()
		}

		this.$refs.base.close()
	}

	confirm() {
		this.resolveCallback(true)

		this.closeModal(false)
	}

	cancel() {
		this.resolveCallback(false)

		this.closeModal(false)
	}
}
