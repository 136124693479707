










































































import Sortable from 'sortablejs'
import Draggable from 'vuedraggable'
import truthy from '@/helpers/truthy'
import sortBy from '@/helpers/sort-by'
import ImageGalleryItem from './ImageGalleryItem.vue'
import DragAndDropMove from '@/mixins/drag-and-drop-move'
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'

@Component({ components: { ImageGalleryItem, Draggable } })
export default class ImageGalleryDropSlot extends Mixins(DragAndDropMove) {
	@Prop({ required: true, default: () => [] })
	readonly images: ProductImageInterface[]

	@Prop({ required: true, type: Array })
	readonly categories: Category

	@Prop({ required: true, type: String })
	readonly title: string

	get list() {
		return this.images.sort(sortBy('order'))
	}

	set list(_images: ProductImageInterface[]) {
		// Just relax ...
	}

	add(e) {
		const list = [...this.list]
		const newImage = this.getImageById(e.item.dataset.imageId)

		list.splice(e.newIndex, 0, newImage)

		list.forEach(({ id }, order) => {
			this.$store.commit('images/UPDATE_IMAGE', {
				id,
				order,
				categories: this.categories
			})
		})
	}

	update(e) {
		const list = [...this.list]

		const image = list.splice(e.oldIndex, 1)

		list.splice(e.newIndex, 0, image.pop())

		list.forEach(({ id }, order) => {
			this.$store.commit('images/UPDATE_IMAGE', {
				id,
				order
			})
		})
	}
}
