
































































































































































































































import Modal from './Modal.vue'
import Checkbox from './Checkbox.vue'
import { annotationMap } from '@/annotations'
import Watermark from '@/annotations/watermark'
import BrandLogo from '@/annotations/brand-logo'
import ImageRule from '@/types/definitions/ImageRule'
import BigReviveLogo from '@/annotations/big-revive-logo'
import MeasurementAnnotation from '@/annotations/measurement'
import { Component, Vue, Prop } from 'vue-property-decorator'

const getDefaultOverlayOptions = () => ({
	MeasurementAnnotation: [false]
})

@Component({ components: { Modal, Checkbox } })
export default class BulkImageEditModal extends Vue {
	@Prop({ type: String })
	readonly applyText: string

	@Prop({ type: String })
	readonly cancelText: string

	public changePadding = false

	protected rule: Omit<ImageRule, 'category'> = {}

	protected resolveCallback: Function = () => ({})
	protected rejectCallback: Function = () => ({})

	protected appliedOverlays = {
		WatermarkAnnotation: false,
		BrandLogoAnnotation: false,
		BigReviveLogoAnnotation: false,
		MeasurementAnnotation: false
	}

	protected overlayOptions = getDefaultOverlayOptions()

	get defaultOverlayOptions() {
		return getDefaultOverlayOptions()
	}

	get globalPadding() {
		return this.$settings.platforms.padding
	}

	openModal(rule: ImageRule) {
		this.changePadding = false

		this.rule = {
			useAsIs: null,
			allowFullPicture: null,
			padding: {
				top: null,
				right: null,
				bottom: null,
				left: null
			},
			overlays: []
		}

		this.appliedOverlays = {
			WatermarkAnnotation: null,
			BrandLogoAnnotation: null,
			BigReviveLogoAnnotation: null,
			MeasurementAnnotation: null
		}

		this.rule.padding = Object.assign(
			{
				top: this.globalPadding,
				right: this.globalPadding,
				bottom: this.globalPadding,
				left: this.globalPadding
			},
			this.rule.padding
		)

		this.units = {
			top: this.rule.padding.top < 1 ? '%' : 'px',
			right: this.rule.padding.right < 1 ? '%' : 'px',
			bottom: this.rule.padding.bottom < 1 ? '%' : 'px',
			left: this.rule.padding.left < 1 ? '%' : 'px'
		}

		this.rule.padding = {
			top:
				this.units.top === '%'
					? this.rule.padding.top * 100
					: this.rule.padding.top,
			right:
				this.units.right === '%'
					? this.rule.padding.right * 100
					: this.rule.padding.right,
			bottom:
				this.units.bottom === '%'
					? this.rule.padding.bottom * 100
					: this.rule.padding.bottom,
			left:
				this.units.left === '%'
					? this.rule.padding.left * 100
					: this.rule.padding.left
		}

		return new Promise((resolve, reject) => {
			this.resolveCallback = resolve
			this.rejectCallback = reject

			this.$refs.base.open()
		})
	}

	closeModal() {
		this.$refs.base.close()
	}

	makeSelection() {
		const selection: StringifiedAnnotation[] = []

		Object.keys(this.appliedOverlays).forEach(overlay => {
			if (this.appliedOverlays[overlay] !== null) {
				selection.push({
					name: overlay,
					args: this.appliedOverlays[overlay]
						? this.overlayOptions[overlay] || []
						: 'delete'
				})
			}
		})

		return selection
	}

	apply() {
		this.rule.padding = {
			top:
				this.units.top === '%'
					? this.rule.padding.top / 100
					: this.rule.padding.top,
			right:
				this.units.right === '%'
					? this.rule.padding.right / 100
					: this.rule.padding.right,
			bottom:
				this.units.bottom === '%'
					? this.rule.padding.bottom / 100
					: this.rule.padding.bottom,
			left:
				this.units.left === '%'
					? this.rule.padding.left / 100
					: this.rule.padding.left
		}

		if (!this.changePadding) {
			this.rule.padding = null
		}

		this.resolveCallback(
			Object.assign(this.rule, { overlays: this.makeSelection() })
		)

		this.closeModal()
	}

	cancel() {
		this.resolveCallback(null)

		this.closeModal()
	}
}
