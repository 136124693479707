import Vue from 'vue'
import Component from 'vue-class-component'

let related: HTMLElement | undefined | null = undefined

@Component
export default class DragAndDropMove extends Vue {
	getImageById(id: string) {
		return this.$store.getters['images/byId'](id)
	}

	start() {
		;(this.$root as Vue & { sorting: boolean }).sorting = true
	}

	end() {
		;(this.$root as Vue & { sorting: boolean }).sorting = false
	}

	move(e: Event & { related: HTMLElement }) {
		if (related && related.classList.contains('drag-over')) {
			related.classList.remove('drag-over')
		}

		if (e.related.tagName === 'UL') {
			related = e.related?.parentElement
		} else if (e.related.tagName === 'LI') {
			related = e.related?.parentElement?.parentElement
		} else {
			related = e.related
		}

		if (related && !related.classList.contains('drag-over')) {
			related.classList.add('drag-over')
		}
	}
}
