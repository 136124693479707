import imageFromSvg from '@/helpers/image-from-svg'

export enum colors {
	dark = '#292B32',
	light = '#FFFFFC'
}

export function getReviveLogo(color: colors) {
	/* eslint-disable max-len */
	return imageFromSvg`
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 541.55 136.04" style="fill:${color}">
			<path d="M76.69,10.05,5.05,100c-11.41,14.24-2.48,35,16.76,35L117,135c12.08,0,21-8.37,22.7-19.61l10.51-66.18c2.58-16.35-3.93-28.71-15.84-34.67L111.8,3.14a29.06,29.06,0,0,0-35.11,6.91Z"/>
			<path d="M240.16,62.56l0,20.85h-1c-13.35,0-22.33,3.44-21.54,25V135H197.52s0-11.51-.06-22.25c-.08-8.39.07-16.34.24-17.77a38,38,0,0,1,15.38-26.06A37.27,37.27,0,0,1,240.16,62.56Z"/>
			<path d="M240.51,99.43v-.26c0-20.22,14.4-36.87,35-36.87,23.65,0,34.49,18.37,34.49,38.45,0,1.59-.14,3.44-.27,5.29H260.46c2,9.12,8.33,13.87,17.31,13.87,6.74,0,11.63-2.11,17.18-7.26l11.5,10.17C299.84,131,290.33,136,277.51,136,256.23,136,240.51,121.1,240.51,99.43Zm49.95-5.94c-1.19-9-6.48-15.07-14.93-15.07s-13.75,6-15.33,15.07Z"/>
			<path d="M303.44,63.62h21.27l15.86,47.44,16-47.44h20.88L349.56,135H331.32Z"/>
			<path d="M380.8,38h21.14V55.82H380.8Zm.53,25.63h20.08V135H381.33Z"/>
			<path d="M405.05,63.62h21.28l15.86,47.44,16-47.44h20.87L451.17,135H432.94Z"/>
			<path d="M472,99.43v-.26c0-20.22,14.41-36.87,35-36.87,23.65,0,34.49,18.37,34.49,38.45,0,1.59-.13,3.44-.26,5.29H492c2,9.12,8.32,13.87,17.31,13.87,6.74,0,11.63-2.11,17.18-7.26L538,122.82C531.38,131,521.86,136,509,136,487.77,136,472,121.1,472,99.43ZM522,93.49c-1.19-9-6.47-15.07-14.93-15.07s-13.74,6-15.33,15.07Z"/>
		</svg>`
	/* eslint-enable max-len */
}

export function getReviveLogoWithSlogan(color1: colors, color2: colors) {
	/* eslint-disable max-len */
	return imageFromSvg`
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484.17 226.12">
			<path fill="${color1}" d="M70.73,8.67,9,86.22C-.89,98.5,6.81,116.41,23.4,116.41h82.06c10.42,0,18.08-7.22,19.58-16.91l9.06-57.07c2.23-14.1-3.39-24.76-13.67-29.9L101,2.71a25.05,25.05,0,0,0-30.28,6Z"/>
			<path fill="${color1}" d="M211.7,54l0,18h-.91c-11.51,0-19.26,3-18.58,21.54v23H174.93s0-9.92-.05-19.19c-.07-7.24.06-14.09.21-15.32a32.77,32.77,0,0,1,13.26-22.47A32.14,32.14,0,0,1,211.7,54Z"/>
			<path fill="${color1}" d="M212,85.75v-.23c0-17.44,12.43-31.79,30.2-31.79,20.4,0,29.75,15.84,29.75,33.16,0,1.37-.12,3-.23,4.56H229.21c1.71,7.86,7.18,12,14.93,12,5.81,0,10-1.82,14.81-6.27l9.92,8.78c-5.7,7.06-13.9,11.39-25,11.39C225.57,117.31,212,104.44,212,85.75Zm43.08-5.13c-1-7.75-5.58-13-12.88-13s-11.85,5.13-13.22,13Z"/>
			<path fill="${color1}" d="M266.27,54.86h18.35l13.67,40.92,13.79-40.92h18L306,116.4H290.32Z"/>
			<path fill="${color1}" d="M333,32.76h18.24V48.14H333Zm.46,22.1h17.32v61.55H333.44Z"/>
			<path fill="${color1}" d="M353.9,54.86h18.35l13.67,40.92,13.79-40.92h18L393.67,116.4H378Z"/>
			<path fill="${color1}" d="M411.67,85.75v-.23c0-17.44,12.42-31.79,30.2-31.79,20.4,0,29.74,15.84,29.74,33.16,0,1.37-.11,3-.22,4.56H428.88c1.71,7.86,7.18,12,14.93,12,5.81,0,10-1.82,14.81-6.27l9.92,8.78c-5.7,7.06-13.91,11.39-25,11.39C425.23,117.31,411.67,104.44,411.67,85.75Zm43.08-5.13c-1-7.75-5.59-13-12.88-13s-11.85,5.13-13.22,13Z"/>
			<rect fill="${color1}" y="164.5" width="484.17" height="61.62"/>
			<path fill="${color2}" d="M45.55,209.08h-7l-5.86-8.76H27.92v8.76h-6V181.71H34.41q4.85,0,7.58,2.41a8.34,8.34,0,0,1,2.74,6.62v.08a8.32,8.32,0,0,1-5.87,8.48Zm-6.92-18v-.08a3.52,3.52,0,0,0-1.25-2.91,5.5,5.5,0,0,0-3.48-1h-6V195H34A5,5,0,0,0,37.4,194,3.53,3.53,0,0,0,38.63,191.13Z"/>
			<path fill="${color2}" d="M69.05,209.08H48.21V181.71H68.85v5.36H54.19v5.55h12.9V198H54.19v5.75H69.05Z"/>
			<path fill="${color2}" d="M95.87,197.15q0,6.1-3.19,9.23c-2.13,2.09-5.06,3.13-8.82,3.13s-6.63-1-8.72-3.11S72,201.31,72,197.35V181.71h6v15.48a7.35,7.35,0,0,0,1.54,5A5.52,5.52,0,0,0,83.94,204a5.62,5.62,0,0,0,4.36-1.68,7,7,0,0,0,1.54-4.89V181.71h6Z"/>
			<path fill="${color2}" d="M119.83,201a7.62,7.62,0,0,1-2.75,6.23,11.19,11.19,0,0,1-7.3,2.25,16.82,16.82,0,0,1-11.61-4.38l3.56-4.26a12.77,12.77,0,0,0,8.17,3.32,5.3,5.3,0,0,0,2.89-.68,2.14,2.14,0,0,0,1.06-1.9v-.08a2.19,2.19,0,0,0-1.09-1.89,17.22,17.22,0,0,0-4.42-1.59,30.86,30.86,0,0,1-3.79-1.15,12.77,12.77,0,0,1-2.76-1.5A5.73,5.73,0,0,1,99.87,193a8.52,8.52,0,0,1-.6-3.38v-.08a7.43,7.43,0,0,1,2.68-6,10.3,10.3,0,0,1,6.9-2.27A15.75,15.75,0,0,1,119,184.8l-3.13,4.53a12.79,12.79,0,0,0-7.11-2.69,4.4,4.4,0,0,0-2.6.66,2,2,0,0,0-.92,1.72v.08a2.16,2.16,0,0,0,1.13,2,22.14,22.14,0,0,0,4.77,1.61,16.66,16.66,0,0,1,6.57,3,6.5,6.5,0,0,1,2.11,5.22Z"/>
			<path fill="${color2}" d="M144,209.08H123.12V181.71h20.64v5.36H129.1v5.55H142V198H129.1v5.75H144Z"/>
			<path fill="${color2}" d="M176.56,209.08l-7.94-10.64H160.8v10.64h-3.08V181.71h11.76a13.33,13.33,0,0,1,4.09.59,9.16,9.16,0,0,1,3.11,1.66,7.47,7.47,0,0,1,2,2.58,7.82,7.82,0,0,1,.7,3.35,8.24,8.24,0,0,1-.54,3.07,6.93,6.93,0,0,1-1.55,2.34,8.66,8.66,0,0,1-2.36,1.67,11.07,11.07,0,0,1-3,.93l8.4,11.18ZM174.44,186a8.2,8.2,0,0,0-5.17-1.44H160.8v11.15h8.43a9.71,9.71,0,0,0,2.82-.39,7.13,7.13,0,0,0,2.24-1.12,5.12,5.12,0,0,0,2-4.18A4.79,4.79,0,0,0,174.44,186Z"/>
			<path fill="${color2}" d="M203.81,184.53H187.12v9.34h14.93v2.81H187.12v9.58H204v2.82H184V181.71h19.78Z"/>
			<path fill="${color2}" d="M227.89,194.34a8.24,8.24,0,0,1-2.32,2.81,10,10,0,0,1-3.42,1.7,14.81,14.81,0,0,1-4.15.57h-6.65v9.66h-3.08V181.71h10.24a13.53,13.53,0,0,1,4.2.61A9.42,9.42,0,0,1,226,184a7.35,7.35,0,0,1,2.07,2.73,9,9,0,0,1,.72,3.66A8.38,8.38,0,0,1,227.89,194.34Zm-4.24-8.27a8.8,8.8,0,0,0-5.36-1.51h-6.94v12.05h6.79a10.54,10.54,0,0,0,3.07-.43,7.18,7.18,0,0,0,2.4-1.24,5.39,5.39,0,0,0,1.52-1.91,5.6,5.6,0,0,0,.53-2.48A5.24,5.24,0,0,0,223.65,186.07Z"/>
			<path fill="${color2}" d="M255.18,209.08h-3.32l-3.21-7.23H233.76l-3.25,7.23h-3.16l12.47-27.56h2.89Zm-14-23.93-6.25,14h12.47Z"/>
			<path fill="${color2}" d="M262,181.71v27.37H258.9V181.71Z"/>
			<path fill="${color2}" d="M286.89,209.08,279,198.44h-7.82v10.64H268V181.71h11.77a13.33,13.33,0,0,1,4.09.59A9.16,9.16,0,0,1,287,184a7.47,7.47,0,0,1,2,2.58,7.82,7.82,0,0,1,.7,3.35,8.24,8.24,0,0,1-.54,3.07,6.93,6.93,0,0,1-1.55,2.34,8.66,8.66,0,0,1-2.36,1.67,11.19,11.19,0,0,1-3,.93l8.4,11.18ZM284.77,186a8.22,8.22,0,0,0-5.17-1.44h-8.47v11.15h8.43a9.71,9.71,0,0,0,2.82-.39,7.24,7.24,0,0,0,2.24-1.12,5.08,5.08,0,0,0,2-4.18A4.78,4.78,0,0,0,284.77,186Z"/>
			<path fill="${color2}" d="M326.65,209.08h-7l-5.86-8.76H309v8.76h-6V181.71h12.51q4.85,0,7.58,2.41a8.34,8.34,0,0,1,2.74,6.62v.08A8.32,8.32,0,0,1,320,199.3Zm-6.92-18v-.08a3.52,3.52,0,0,0-1.25-2.91,5.5,5.5,0,0,0-3.48-1h-6V195h6.1A5,5,0,0,0,318.5,194,3.53,3.53,0,0,0,319.73,191.13Z"/>
			<path fill="${color2}" d="M350.15,209.08H329.31V181.71H350v5.36H335.29v5.55h12.9V198h-12.9v5.75h14.86Z"/>
			<path fill="${color2}" d="M374.27,187.26h-8.33v21.82h-6V187.26h-8.33v-5.55h22.68Z"/>
			<path fill="${color2}" d="M400.07,209.08h-6v-11H383v11h-6V181.71h6v10.83h11.1V181.71h6Z"/>
			<path fill="${color2}" d="M410.16,209.08h-6V181.71h6Z"/>
			<path fill="${color2}" d="M438.54,209.08h-5.12l-13.25-17.4v17.4h-5.95V181.71h5.56l12.82,16.85V181.71h5.94Z"/>
			<path fill="${color2}" d="M467.24,209.08H460l-8.49-11.65-3.17,3.28v8.37h-6V181.71h6v12l11.11-12h7.27l-11.14,11.61Z"/>
		</svg>`
	/* eslint-enable max-len */
}

export async function loadFont(family: string, file: string) {
	const loadedFont = await new FontFace(family, file).load()

	document.fonts.add(loadedFont)
}
