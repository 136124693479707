import Vue from 'vue'
import axios from '@/helpers/axios'
import sortBy from '@/helpers/sort-by'
import { Module, mapState } from 'vuex'
import { ProductType } from '@/types/definitions/ProductType'
import { StringifedOriginalData } from '@/types/definitions/misc'
import { StoredProductType } from '@/types/definitions/StoredProductType'

Vue.use({
	install(v) {
		v.mixin({
			computed: {
				...mapState({
					$product: (state: any) => state.product.product
				})
			}
		})
	}
})

export type ProductState = {
	product?: ProductType
	children: { [id: string]: StoredProductType }
}

export default {
	state: {
		product: undefined,
		children: {}
	},

	getters: {
		hasProduct(state) {
			return !!state.product
		},

		product(state) {
			return state.product || {}
		}
	},

	mutations: {
		SET_PRODUCT(state: ProductState, product: ProductType) {
			state.product = product
		},

		ADD_CHILD(state, child: StoredProductType) {
			state.children[child.sku] = child
		},

		CLEAR_PRODUCT(state: ProductState) {
			state.product = undefined
			state.children = {}
		}
	},

	actions: {
		async HANDLE_SET_PRODUCT(
			{ dispatch, commit, rootState },
			product: ProductType
		) {
			const limitPlatforms = Object.keys(
				rootState.platforms.platforms
			).map(p => `platforms[]=${p}`)

			return Promise.allSettled(
				product.children
					.sort(sortBy('kind.weight', 'desc'))
					.map(async child => {
						const childData = (
							await axios.get(
								`product/${child.sku}?${limitPlatforms.join(
									'&'
								)}`
							)
						).data

						commit('ADD_CHILD', childData)

						// Remove categories where necessary and pre-associate child's id
						if (product.type !== 'multi') {
							Object.values(
								childData.originals as StringifedOriginalData
							).map(original => {
								if (typeof original.data === 'string') {
									original.data = JSON.parse(original.data)
								}

								original.data.associatedProduct = child.sku

								if (original.data.categories[0] === 'special') {
									original.data.categories = []
								}
							})
						}

						return dispatch(
							'images/LOAD_ORIGINALS',
							childData.originals,
							{ root: true }
						)
					})
			)
		}
	}
} as Module<ProductState, any>
