import ImageData from '@/types/ImageData'
import rehydrate from '@/helpers/rehydrate'
import PermanentImage from './PermanentImage'
import promise from '!!raw-loader!./promise.png.base64'
import shipping from '!!raw-loader!./shipping.png.base64'
import { ProductImageInterface } from '@/types/definitions/ProductImageInterface'

export default function getPermanentImages(): ProductImageInterface[] {
	const images: Partial<ProductImageInterface>[] = []

	images.push({
		// @ts-ignore
		_ready: true,
		_id: 'PERM_SHIPPING',
		_name: 'PERM_SHIPPING',
		_image: new ImageData(shipping, { width: 2500, height: 2500 }),
		_categories: ['permanent', null],
		_order: 0,
		_objects: [],
		_prohibitDelete: true,
		_prohibitResetCategory: true
	})

	images.push({
		// @ts-ignore
		_ready: true,
		_id: 'PERM_PROMISE',
		_name: 'PERM_PROMISE',
		_image: new ImageData(promise, { width: 2500, height: 2500 }),
		_categories: ['permanent', null],
		_objects: [],
		_order: 1,
		_prohibitDelete: true,
		_prohibitResetCategory: true
	})

	// @ts-ignore
	return images.map(i => rehydrate(i, PermanentImage))
}
