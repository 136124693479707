






















































































import Modal from './Modal.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'

const loadingTexts = [
	'Loading product data...',
	'Loading original images...',
	'Loading product images...',
	'Adding watermarks...',
	"Wondering why it's not done loading yet..."
]

@Component({ components: { Modal } })
export default class ImageLoadingModal extends Vue {
	protected loadingText: string = ''

	open() {
		this.$refs.base.open()

		const changeLoadingText = () => {
			const currentIndex = loadingTexts.indexOf(this.loadingText)

			this.loadingText =
				loadingTexts[(currentIndex + 1) % loadingTexts.length]
		}

		this.loadingTextChanger = setInterval(changeLoadingText, 8000)

		changeLoadingText()
	}

	close() {
		this.$refs.base.close()

		clearInterval(this.loadingTextChanger)

		delete this.loadingTextChanger
	}
}
