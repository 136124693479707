


























































































import Draggable from 'vuedraggable'
import DisplayItem from './DisplayItem.vue'
import partition from '@/helpers/partition'
import ProductImage from '@/types/product-image'
import ImageGalleryItem from './ImageGalleryItem.vue'
import DragAndDropMove from '@/mixins/drag-and-drop-move'
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'

@Component({ components: { DisplayItem, ImageGalleryItem, Draggable } })
export default class ImageGallerySpecialDropSlot extends Mixins(
	DragAndDropMove
) {
	@Prop({ required: true })
	readonly image: ProductImage[]

	@Prop({ required: true, type: Array })
	readonly categories: Category

	@Prop({ required: true, type: String })
	readonly title: String

	@Prop({ required: true, type: String })
	readonly color: String

	get list() {
		return this.image ? [this.image] : []
	}

	set list(_images: ProductImageInterface[]) {
		// Just relax ...
	}

	remove(image) {
		this.$store.commit('images/RESET_CATEGORIES', image)
	}

	add(e) {
		const newImage = this.getImageById(e.item.dataset.imageId)

		if (this.image) {
			this.$store.commit('images/UPDATE_IMAGE', {
				id: this.image.id,
				order: newImage.order,
				categories: newImage.categories
			})
		}

		this.$store.commit('images/UPDATE_IMAGE', {
			id: newImage.id,
			order: 0,
			categories: this.categories
		})
	}
}
