import Vue from 'vue'
import { Module } from 'vuex'
import { tap } from '@/helpers/tap'
import unique from '@/helpers/unique'
import sortBy from '@/helpers/sort-by'
import flatten from '@/helpers/flatten'
import { OptionState } from './settings'
import ImageData from '@/types/ImageData'
import rehydrate from '@/helpers/rehydrate'
import loadImage from '@/helpers/load-image'
import { PlatformsState } from './platforms'
import ProductImage from '@/types/ProductImage'
import { instantiateAnnotations } from '@/annotations'
import { ImageRule } from '@/types/definitions/ImageRule'
import getPermanentImages from './helper/get-permanent-images'
import LocalizedObjectAnnotation from '@/types/LocalizedObjectAnnotation'
import { ImageDataInterface } from '@/types/definitions/ImageDataInterface'
import { ProductImageInterface } from '@/types/definitions/ProductImageInterface'
import serializeImageRule, {
	serializePadding,
	serializeDimensions
} from '@/helpers/serialize-image-rule'

export type ImagesState = {
	images: ProductImageInterface[]
	cache: {
		[id: string]: {
			[cache_key: string]: () => Promise<ImageDataInterface>
		}
	}
}

export type ImageCompositionData = {
	id: ProductImageInterface['id']
	rule: ImageRule
	dimensions: DimensionType
	globalPadding: number
	boundingBoxPadding: number
	additionalCreationArgs: any[]
}

function getBaseState(): ImagesState {
	return {
		images: getPermanentImages(),
		cache: {}
	}
}

export default <
	Module<
		ImagesState,
		{
			platforms: PlatformsState
			settings: OptionState
		}
	>
>{
	namespaced: true,

	state: getBaseState(),

	getters: {
		byId(state) {
			return (id: string) => state.images.find(i => i.id === id)
		},

		ids(state): ProductImageInterface['id'][] {
			return state.images.map(i => i.id)
		},

		imageCount(state) {
			return state.images.filter(i => i.category !== 'permanent').length
		},

		categories(state) {
			return state.images
				.map(i => i.category as string)
				.reduce(unique, [] as string[])
		},

		sorted(state): ProductImageInterface[] {
			return state.images.sort(sortBy('order'))
		},

		byCategory(
			state,
			getters
		): (category: string, sorted: boolean) => ProductImageInterface[] {
			return (category: string, sorted: boolean = true) =>
				(sorted ? getters.sorted : state.images).filter(
					(i: ProductImageInterface) => i.category === category
				)
		},

		byCategoryAndSubCategory(
			_state,
			getters
		): (
			category: string,
			subCategory?: string,
			sorted?: boolean
		) => ProductImageInterface[] {
			return (
				category: string,
				subCategory?: string,
				sorted?: boolean
			) => {
				let images = getters.byCategory(
					category,
					sorted
				) as ProductImageInterface[]

				if (typeof subCategory !== 'undefined') {
					images = images.filter(i => i.subCategory === subCategory)
				}

				return images
			}
		},

		orderLower(state): (lowerThan: number) => ProductImageInterface[] {
			return (lowerThan: number) =>
				state.images.filter(p => p.order < lowerThan)
		},

		orderHigher(state): (higherThan: number) => ProductImageInterface[] {
			return (higherThan: number) =>
				state.images.filter(p => p.order > higherThan)
		},

		hasCached(state): (id: string, key: string) => boolean {
			return (id: string, key: string) =>
				!(
					typeof state.cache[id] === 'undefined' ||
					typeof state.cache[id][key] === 'undefined'
				)
		},

		cached(
			state,
			getters
		): (id: string, key: string) => Promise<ImageDataInterface> | null {
			return (id: string, key: string) =>
				getters.hasCached(id, key) ? state.cache[id][key]() : null
		}
	},

	mutations: {
		PUSH_IMAGE(state, image: ProductImageInterface) {
			state.images.push(image)
		},

		DELETE_IMAGE(
			state,
			image: ProductImageInterface | ProductImageInterface['id']
		) {
			if (typeof image !== 'string') {
				image = image.id
			}

			state.images = state.images.filter(i => i.id !== image)
			delete state.cache[image]
		},

		CLEAR_CACHE(state, image?: string | ProductImageInterface) {
			if (image) {
				if (typeof image !== 'string') {
					image = image.id
				}

				delete state.cache[image]
			} else {
				state.cache = getBaseState().cache
			}
		},

		CLEAR(state) {
			const baseState = getBaseState()

			Object.keys(baseState).forEach(key => {
				// @ts-ignore
				state[key as keyof ImagesState] =
					baseState[key as keyof ImagesState]
			})
		},

		SLICE_IMAGES(state, slice: number | number[]) {
			if (!Array.isArray(slice)) {
				slice = [slice]
			}

			state.images = state.images.slice(...slice)
		},

		UPDATE_IMAGE(state, data: { id: string; [key: string]: any }) {
			const image = state.images.find(
				i => i.id === data.id
			) as ProductImageInterface & { [key: string]: any }

			if (image) {
				Object.keys(data).forEach(key => {
					try {
						image[key] = data[key]
					} catch (e) {
						try {
							image[`_${key}`] = data[key]
						} catch (e2) {
							throw e
						}
					}
				})
			}
		},

		RESET_CATEGORIES(state, image: string | ProductImageInterface) {
			if (image) {
				if (typeof image !== 'string') {
					image = image.id
				}

				;(
					state.images.find(i => i.id === image) || {
						categories: null
					}
				).categories = []
			} else {
				state.images = state.images.map(
					tap(i => !i.prohibitDelete && (i.categories = []))
				)
			}
		},

		ASSIGN_PRODUCT_ID(state, { product, image }) {
			if (typeof image !== 'string') {
				image = image.id
			}

			const img = state.images.find(i => i.id === image)

			if (img) {
				if (typeof product !== 'string') {
					product = product.sku
				}

				img.associatedProduct = product
			}
		},

		CACHE(
			state,
			{
				id,
				key,
				value
			}: {
				id: keyof ImagesState
				key: string
				value: Promise<ImageDataInterface>
			}
		) {
			if (typeof state.cache[id] === 'undefined') {
				state.cache[id] = {}
			}

			let fulfilledValue: any
			let type: 'resolve' | 'reject'

			value = Promise.resolve(value)

			value.then(
				i => {
					fulfilledValue = i
					type = 'resolve'

					return i
				},
				i => {
					fulfilledValue = i
					type = 'reject'

					return i
				}
			)

			state.cache[id][key] = () => {
				// @ts-ignore
				return value.then(() => Promise[type](fulfilledValue))
			}
		},

		ADD_BOUNDING_BOX(state, image: string | ProductImage) {
			if (typeof image !== 'string') {
				image = image.id
			}

			const img = state.images.find(i => i.id === image)

			if (img) {
				Vue.set(img, 'objects', [
					{
						mid: 'custom',
						name: 'Custom',
						score: 1,
						boundingPoly: {
							normalizedVertices: [
								{ x: 0.25, y: 0.25 },
								{ x: 0.75, y: 0.25 },
								{ x: 0.75, y: 0.75 },
								{ x: 0.25, y: 0.75 }
							]
						}
					}
				])
			}
		},

		REMOVE_BOUNDING_BOX(state, image: string | ProductImage) {
			if (typeof image !== 'string') {
				image = image.id
			}

			const img = state.images.find(i => i.id === image)

			if (img) {
				Vue.set(img, 'objects', [
					{
						mid: '',
						name: '',
						score: 1,
						boundingPoly: {
							normalizedVertices: [
								{ x: 0, y: 0 },
								{ x: 1, y: 0 },
								{ x: 1, y: 1 },
								{ x: 0, y: 1 }
							]
						}
					}
				])
			}
		},

		UPDATE_BOUNDING_BOX(
			state,
			{ id, bb }: { id: string | ProductImage; bb: padding }
		) {
			if (typeof id !== 'string') {
				id = id.id
			}

			const img = state.images.find(i => i.id === id)

			if (img) {
				Vue.set(img.objects[0].boundingPoly, 'normalizedVertices', [
					{ x: bb.left, y: bb.top },
					{ x: bb.right, y: bb.top },
					{ x: bb.right, y: bb.bottom },
					{ x: bb.left, y: bb.bottom }
				])
			}
		}
	},

	actions: {
		RESET_ORDER({ getters, commit }, categories: string | string[]) {
			if (!categories) {
				return
			}

			if (!Array.isArray(categories)) {
				categories = [categories]
			}

			getters
				.byCategoryAndSubCategory(...categories)
				.forEach(({ id }: ProductImageInterface, order: number) => {
					commit('UPDATE_IMAGE', { id, order })
				})
		},

		RESET_ORDER_BY_NAME(
			{ getters, commit },
			categories: string | string[]
		) {
			if (!categories) {
				return
			}

			if (!Array.isArray(categories)) {
				categories = [categories]
			}

			getters
				.byCategoryAndSubCategory(...categories)
				.sort(sortBy('name', 'asc', true))
				.forEach(({ id }: ProductImageInterface, order: number) => {
					commit('UPDATE_IMAGE', { id, order })
				})
		},

		UPDATE_IMAGE_KEEP_ORDER(
			{ getters, commit, dispatch },
			data: {
				id: ProductImageInterface['id']
				order: ProductImageInterface['order']
			} & Partial<ProductImageInterface>
		) {
			const old = getters.byId(data.id)

			if (old.category === 'special') {
				return commit('UPDATE_IMAGE', data)
			}

			if (old.order === data.order - 1) {
				const id = getters.orderHigher(data.order - 1).shift().id

				return dispatch('UPDATE_IMAGE_KEEP_ORDER', {
					id,
					order: data.order - 1
				})
			}

			// Update images with a higher order to be order + 1
			getters
				.orderHigher(data.order - 1)
				.forEach(({ id, order }: ProductImageInterface) => {
					commit('UPDATE_IMAGE', {
						id,
						order: order + 1
					})
				})

			commit('UPDATE_IMAGE', data)
			dispatch('RESET_ORDER', data.categories)
		},

		REANNOTATE_IMAGE({ getters, commit }, id: ProductImageInterface['id']) {
			const image = getters.byId(id) as ProductImage

			if (image) {
				return image
					.fetchAnnotations()
					.then(tap(result => commit('UPDATE_IMAGE', result)))
			}

			return Promise.reject(`Cannot find image with ID ${id}`)
		},

		GET_CACHED_OR_GENERATE(
			{ commit, getters },
			data: {
				id: string
				key: string
				generator: Function
			}
		) {
			if (!getters.hasCached(data.id, data.key)) {
				commit('CACHE', {
					id: data.id,
					key: data.key,
					value: data.generator()
				})
			}

			return getters.cached(data.id, data.key)
		},

		GET_IMAGE(
			{ getters, dispatch },
			data: ImageCompositionData & {
				type: 'original' | 'base'
			}
		) {
			const format =
				data.type === 'original'
					? 'full'
					: data.dimensions.width / data.dimensions.height

			const serializedPadding =
				data.type === 'base'
					? data.rule.allowFullPicture
						? 'full'
						: serializePadding(
								data.rule.padding || {},
								data.globalPadding
						  )
					: ''

			const cacheKeyBase = `i-${data.type.charAt(0)}${serializedPadding}`

			return dispatch('GET_CACHED_OR_GENERATE', {
				id: data.id,
				key: `${cacheKeyBase}-max-${format}`,
				generator: () => {
					const productImage = getters.byId(
						data.id
					) as ProductImageInterface

					return productImage.generateImage(
						data.type,
						[
							data.rule,
							data.dimensions,
							data.globalPadding,
							data.boundingBoxPadding
						].concat(data.additionalCreationArgs || [])
					)
				}
			})
		},

		GET_IMAGE_COMPOSITION(actionContext, data: ImageCompositionData) {
			const { dispatch, rootGetters, rootState, commit } = actionContext

			if (data.rule.useAsIs) {
				return dispatch(
					'GET_IMAGE',
					Object.assign({}, data, { type: 'original' })
				)
			}

			const compositionName = serializeImageRule(
				data.rule,
				data.globalPadding
			)

			const context = {
				dispatch,
				commit,
				state: rootState,
				getters: rootGetters,
				rootGetters,
				rootState
			}

			return dispatch('GET_CACHED_OR_GENERATE', {
				id: data.id,
				// eslint-disable-next-line max-len
				key: `v${compositionName}${serializeDimensions(
					data.dimensions
				)}`,
				generator: () => {
					return dispatch(
						'GET_IMAGE',
						Object.assign({}, data, { type: 'base' })
					)
						.then(i => {
							let p = Promise.resolve(i)

							instantiateAnnotations(data.rule.overlays).forEach(
								annotator => {
									p = p.then(i =>
										annotator.annotate(i, context)
									)
								}
							)

							return p
						})
						.then((i: ImageData) => i.scale(data.dimensions))
				}
			})
		},

		GET_IMAGE_COMPOSITION_FOR_SAVING(
			{ dispatch },
			data: ImageCompositionData
		) {
			const rule = serializeImageRule(data.rule, data.globalPadding)
			const dimensions = serializeDimensions(data.dimensions)

			let key = `f-${data.id}${rule}${dimensions}`

			if (data.rule.useAsIs) {
				key = `${data.id}-as-is`
			}

			return dispatch('GET_IMAGE_COMPOSITION', data).then(image => ({
				image,
				key
			}))
		},

		LOAD_ORIGINALS({ dispatch }, originals) {
			Object.keys(originals).forEach(key => (originals[key].id = key))

			return Promise.allSettled(
				Object.values(originals).map(o => dispatch('LOAD_ORIGINAL', o))
			)
		},

		async LOAD_ORIGINAL({ commit, getters, rootGetters }, original) {
			if (typeof original.data !== 'object') {
				original.data = JSON.parse(original.data)
			}

			if (getters.byId(original.id)) {
				return Promise.reject('Already loaded')
			}

			let imageData
			if (!original.data.dimensions) {
				imageData = await loadImage(original.image).then(
					({ img, canvas }) =>
						new ImageData(canvas.toDataURL('image/jpeg', 1), {
							width: img.width,
							height: img.height
						})
				)
			} else {
				imageData = new ImageData(
					original.image,
					original.data.dimensions,
					true
				)
			}

			const data = {
				_id: original.id,
				_name: `${original.id}.jpg`,
				_image: imageData,
				_objects: original.data.objects.map(
					(object: LocalizedObjectAnnotation) =>
						rehydrate(object, LocalizedObjectAnnotation)
				),
				_order: original.data.order || 0,
				_categories: original.data.categories || [],
				_associatedProduct:
					original.data.associatedProduct || rootGetters.product.sku
			}

			// If this is a specially categorized image and we already have
			// another image in this category, remove this image's category
			if (
				data._categories[0] === 'special' &&
				getters.byCategoryAndSubCategory(...data._categories).length
			) {
				data._categories = []
			}

			// @ts-ignore
			const image = rehydrate(data, ProductImage)

			commit('PUSH_IMAGE', image)

			return Promise.resolve(image)
		},

		LOAD_IMAGES(
			{ dispatch, rootState },
			images: {
				[key: string]: Array<{
					image: string
					original: string
					rule: string
					platform?: string
				}>
			}
		) {
			const allImages = Object.keys(images)
				.map(
					platform =>
						images[platform].map(i => (i.platform = platform)) &&
						images[platform]
				)
				.reduce(flatten, [])

			const platforms = rootState.platforms.platforms
			const settings = rootState.settings.settings

			return Promise.allSettled(
				allImages.map(image => {
					const rule = JSON.parse(image.rule)

					let compositionName

					try {
						compositionName = serializeImageRule(
							rule,
							settings.platforms.padding
						)
					} catch (e) {
						const message = e.toString()

						if (
							message.startsWith(
								"TypeError: Cannot read property '"
							) &&
							message.endsWith("' of null")
						) {
							return Promise.reject()
						}

						throw e
					}

					const dimensions =
						platforms[image.platform as string].settings.format

					return dispatch('GET_CACHED_OR_GENERATE', {
						id: image.original,
						// eslint-disable-next-line max-len
						key: `v${compositionName}${serializeDimensions(
							dimensions
						)}`,
						generator: () =>
							new ImageData(image.image, dimensions, true)
					})
				})
			)
		},

		LOAD({ dispatch }, { originals, images }) {
			return Promise.allSettled([
				dispatch('LOAD_IMAGES', images),
				dispatch('LOAD_ORIGINALS', originals)
			])
		}
	}
}
