




















































































































import { Component, Vue } from 'vue-property-decorator'
import ImageGalleryHeader from '@/components/ImageGalleryHeader.vue'
import ImageGalleryDropSlot from '@/components/ImageGalleryDropSlot.vue'
import ImageGallerySpecialDropSlot from '@/components/ImageGallerySpecialDropSlot.vue'

@Component({
	components: {
		ImageGalleryDropSlot,
		ImageGallerySpecialDropSlot,
		ImageGalleryHeader
	}
})
export default class ImageGallery extends Vue {
	get measurementsImage() {
		return this.byCategoryAndSub('special', 'measurements').pop()
	}

	get colorsImage() {
		return this.byCategoryAndSub('special', 'colors').pop()
	}

	get logoImage() {
		return this.byCategoryAndSub('special', 'logo').pop()
	}

	get materialDetailImage() {
		return this.byCategoryAndSub('special', 'materialDetail').pop()
	}

	get titleImage() {
		return this.byCategoryAndSub('special', 'title').pop()
	}

	get unclippedImage() {
		return this.byCategoryAndSub('special', 'title-unclipped').pop()
	}

	get damageImages() {
		return this.byCategoryAndSub('detail', 'damages')
	}

	get detailImages() {
		return this.byCategoryAndSub('detail', null)
	}

	get alternateImages() {
		return this.byCategoryAndSub('alternate', null)
	}

	get functionImages() {
		return this.byCategoryAndSub('functions', null)
	}

	get byId() {
		return this.$store.getters['images/byId']
	}

	get byCategoryAndSub() {
		return this.$store.getters['images/byCategoryAndSubCategory']
	}
}
