


























































































import axios from '@/helpers/axios'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
	protected email: string = ''
	protected password: string = ''
	protected error: any = null

	get action() {
		return process.env.VUE_APP_BACKEND_BASE
	}

	get credentials() {
		return {
			email: this.email,
			password: this.password
		}
	}

	get copyrightNotice() {
		return `&copy; ${new Date().getFullYear()} Revive Interior GmbH. All rights reserved.`
	}

	get errorMessage(): string {
		if (this.error.status === 403) {
			return this.$t('Invalid credentials.')
		}

		return this.$t(this.error.data.message) || this.$t('Unknown error.')
	}

	get returnTo(): string {
		return this.$store.state.auth.returnTo || '/'
	}

	login() {
		this.$store
			.dispatch('LOGIN', this.credentials)
			.then(() => this.$router.push(this.returnTo))
			.catch(e => {
				this.password = ''

				this.$refs.password.focus()

				// eslint-disable-next-line no-console
				this.error = e
			})
	}
}
