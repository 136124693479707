import ImageData from '@/types/ImageData'
import { Store, ActionContext } from 'vuex'
import loadImage from '@/helpers/load-image'
import ImageAnnotation from '@/types/ImageAnnotation'
import { colors, getReviveLogoWithSlogan, loadFont } from './annotation-helpers'
import { svgToBase64 } from '@/helpers/image-from-svg'

type ConditionMap = {
	'Minimale Gebrauchsspuren': string
	Neuwertig: string
	'Leichte Gebrauchsspuren': string
	'Sichtbare Gebrauchsspuren': string
	'Top Zustand': string
}

type LanguageServiceProvider = {
	[key: string]: {
		getCondition: (condition: keyof ConditionMap) => string
		getBrandText: (brand: string) => string
		getMaterialText: (material: { id: string; name: string }) => string
		getSetText: () => string
	}
}

/* eslint-disable max-len */
const checkmarkImage = svgToBase64(`
	<svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 19.21315 18.294994">
		<path fill="${colors.light}" d="M4.77468 18.29499c-.275-.00355-1.4618-1.77844-2.63734-3.9442L0 10.41304l.55401-.554c.3047-.30471 1.12368-.55401 1.81995-.55401H3.6399l.63639 2.00509.63639 2.00508 5.06447-5.54133c2.78546-3.04773 6.00307-6.04366 7.15024-6.6576L19.21315 0l-4.20944 4.78218c-2.3152 2.6302-5.45136 6.74803-6.96924 9.15073-1.51788 2.4027-2.98479 4.36564-3.25979 4.36208z"/>
	</svg>
`)
/* eslint-enable max-len */

export default class MeasurementAnnotation extends ImageAnnotation {
	constructor(
		private withLogo = true,
		private language = 'de',
		private relativeToWidth = 2500
	) {
		super(Array.from(arguments))
	}

	async annotate(
		image: ImageData,
		store: Store<any> | ActionContext<any, any>
	): Promise<ImageData> {
		await loadFont(
			'gotham-light',
			"url('/fonts/gotham-light.woff2') format('woff2')"
		)

		const img = await image.getCanvasWithImage()

		return this.draw(img, store)
	}

	async draw(
		[canvas, ctx, dimensions]: [
			HTMLCanvasElement,
			CanvasRenderingContext2D,
			DimensionType
		],
		store: Store<any> | ActionContext<any, any>
	) {
		const scaleFactor = dimensions.width / this.relativeToWidth

		await this.drawBox(ctx, scaleFactor, dimensions)

		if (this.withLogo) {
			await this.drawLogo(ctx, scaleFactor, dimensions)
		}

		await this.drawCheckmarks(ctx, scaleFactor, dimensions)

		this.writeText(ctx, scaleFactor, dimensions, store.getters.product)

		return new ImageData(canvas.toDataURL('image/jpeg', 1), dimensions)
	}

	async drawBox(
		ctx: CanvasRenderingContext2D,
		scaleFactor: number,
		dimensions: DimensionType
	) {
		const boxSize: DimensionType = {
			height: 571 * scaleFactor,
			width: dimensions.width
		}

		const rect = this.getPositioning(
			{ bottom: 0 } as position,
			boxSize,
			dimensions
		)

		ctx.fillStyle = colors.dark
		ctx.fillRect(...rect)
	}

	async drawLogo(
		ctx: CanvasRenderingContext2D,
		scaleFactor: number,
		imageDimensions: DimensionType
	) {
		const image = await getReviveLogoWithSlogan(colors.light, colors.dark)

		const dimensions = {
			width: 742 * scaleFactor,
			height: 352 * scaleFactor
		}

		ctx.drawImage(
			image,
			...this.getPositioning(
				{
					right: 109 * scaleFactor,
					bottom: 109 * scaleFactor
				},
				dimensions,
				imageDimensions
			)
		)
	}

	async drawCheckmarks(
		ctx: CanvasRenderingContext2D,
		scaleFactor: number,
		dimensions: DimensionType
	) {
		const { img } = await loadImage(checkmarkImage)

		const size = {
			width: 130 * scaleFactor,
			height: 130 * scaleFactor
		}

		for (let i = 0; i < 3; i++) {
			ctx.drawImage(
				img,
				...this.getPositioning(
					{
						left: 60 * scaleFactor,
						bottom: (92 + i * 130) * scaleFactor
					},
					size,
					dimensions
				)
			)
		}
	}

	writeText(
		ctx: CanvasRenderingContext2D,
		scaleFactor: number,
		dimensions: DimensionType,
		product: any
	) {
		const fontSize = 84 * scaleFactor

		const textPosition = this.getPositioning(
			{ left: 274 * scaleFactor, bottom: 97 * scaleFactor },
			{ height: fontSize, width: 0 },
			dimensions
		)

		const lineHeight = 140

		const languageServices = this.getLanguageDriver(this.language)

		ctx.fillStyle = colors.light
		ctx.font = `${fontSize}px gotham-light`
		ctx.textBaseline = 'top'

		ctx.fillText(
			languageServices.getBrandText(product.brand.name),
			textPosition[0],
			textPosition[1] - 2 * lineHeight * scaleFactor
		)

		ctx.fillText(
			languageServices.getMaterialText(product.material),
			textPosition[0],
			textPosition[1] - lineHeight * scaleFactor
		)

		let conditionText = languageServices.getCondition(product.condition)

		// Sets don't need to have single condition as they consist of multiple products
		// with multiple conditions. We should let the customer know.
		if (product.type === 'set') {
			conditionText = languageServices.getSetText()
		}

		ctx.fillText(conditionText, textPosition[0], textPosition[1])
	}

	getLanguageDriver(language: string) {
		const languageProviders: LanguageServiceProvider = {
			de: {
				getCondition(condition: string) {
					return condition
				},

				getBrandText(brand: string) {
					return `Original ${brand}`
				},

				getMaterialText(material: {
					id: string
					name: string
				}): string {
					return (
						{
							'1': 'Hochwertiges Echtleder',
							'2': 'Hochwertiger Stoff',
							'3': 'Hochwertiger Samt',
							'6': 'Hochwertiges Echtleder',
							'9': 'Hochwertiger Stoff',
							'11': 'Hochwertiger Cord',
							'13': 'Hochwertiges Kunstleder',
							'14': 'Hochwertiger Velours',
							'15': 'Hochwertige Mikrofaser',
							'19': 'Hochwertiges Alcantara',
							'20': 'Hochwertiges Fell',
							'22': 'Hochwertiges Ziegenleder'
						}[material.id] || `Material: ${material.name}`
					)
				},

				getSetText() {
					return 'Enthält mehrere Artikel';
				}
			},

			en: {
				getCondition(condition: keyof ConditionMap) {
					const conditionTranslations = {
						'Minimale Gebrauchsspuren': 'Minimal signs of wear',
						Neuwertig: 'Like new',
						'Leichte Gebrauchsspuren': 'Slight signs of wear',
						'Sichtbare Gebrauchsspuren': 'Visible signs of wear',
						'Top Zustand': 'Top condition'
					}

					return conditionTranslations[condition]
				},

				getBrandText(brand: string) {
					return `Original ${brand}`
				},

				getMaterialText(material: {
					id: string
					name: string
				}): string {
					return (
						{
							'1': 'High quality genuine leather',
							'10': 'Wood',
							'11': 'High quality corduroy',
							'12': 'General upholstery',
							'13': 'High quality artificial leather',
							'14': 'High quality velour',
							'15': 'High quality microfiber',
							'16': 'Glass',
							'17': 'Walnut',
							'18': 'Ash veneer',
							'19': 'High quality alcantara',
							'2': 'High quality fabric',
							'20': 'High quality fur',
							'21': 'Concrete',
							'22': 'High quality goat leather',
							'23': 'Slate',
							'24': 'Stoneware',
							'25': 'Polypropylene',
							'3': 'High quality velvet',
							'5': 'Ash',
							'6': 'High quality genuine leather',
							'7': 'Metal',
							'8': 'Chrome',
							'9': 'High quality fabric'
						}[material.id] || ''
					)
				},

				getSetText() {
					return 'Contains multiple items';
				}
			}
		}

		return languageProviders[language]
	}
}
