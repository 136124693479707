


































































import Modal from './Modal.vue'
import isNot from '@/helpers/is-not'
import ImagePickerItem from './ImagePickerItem.vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ components: { Modal, ImagePickerItem } })
export default class ImagePicker extends Vue {
	@Prop({ required: true, type: Array })
	readonly added: string[]

	@Prop({ type: String })
	readonly selectionText: string

	@Prop({ type: String })
	readonly cancelText: string

	protected resolveCallback: Function = () => ({})
	protected rejectCallback: Function = () => ({})

	public selection: string[] = []

	get notAddedImages() {
		return this.$store.getters['images/ids'].filter(id => {
			return !(this.added || []).includes(id)
		})
	}

	get categorizedImages() {
		return this.notAddedImages.map(this.byId).reduce((carry, pi) => {
			const category =
				pi.category.charAt(0).toUpperCase() + pi.category.substr(1)

			if (!carry[category]) {
				carry[category] = []
			}

			carry[category].push(pi.id)

			return carry
		}, {})
	}

	get hasImages() {
		return this.notAddedImages.length
	}

	byId(id: string) {
		return this.$store.getters['images/byId'](id)
	}

	openModal() {
		return new Promise((resolve, reject) => {
			this.resolveCallback = resolve
			this.rejectCallback = reject

			this.$refs.base.open()
		})
	}

	makeSelection(e: Event, id: string) {
		if (!e.ctrlKey) {
			return this.selectSingle(id)
		}

		this.selectMultiple(id)
	}

	selectSingle(id: string) {
		this.selection = [id]

		this.select()
	}

	selectMultiple(id: string) {
		if (!this.selection.includes(id)) {
			this.selection.push(id)
		} else {
			this.selection = this.selection.filter(isNot(id))
		}
	}

	closeModal() {
		this.selection = []
		this.$refs.base.close()
	}

	select() {
		this.resolveCallback(this.selection)

		this.closeModal()
	}

	cancel() {
		this.resolveCallback([])

		this.closeModal()
	}
}
