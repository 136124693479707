

















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class PromisedImage extends Vue {
	@Prop({ required: true })
	readonly src: Promise<ImageData> | ImageData

	@Prop({ type: Object, default: () => ({}) })
	readonly dimensions: dimensions

	@Prop({ type: Boolean, default: true })
	readonly handleError: boolean

	@Prop({ type: Boolean, default: false })
	readonly forceError: boolean

	public imageLoaded: boolean = false
	private loadedImage: string = null
	private error: boolean = false

	created() {
		this.resetLoadedImage()
	}

	get defaultImage() {
		/* eslint-disable max-len */
		return `
			<svg class="fill-current uppercase placeholder-svg" style="font-size:6px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="100%" height="100%">
				<path d="M44.243 16.799a3.762 3.762 0 00-3.757 3.758v4.661H7.514v-4.661c0-2.072-1.686-3.758-3.757-3.758S0 18.484 0 20.557v8.419a3.762 3.762 0 003.757 3.757h1.478v3.497a1.515 1.515 0 103.032 0v-3.497h31.469v3.497a1.515 1.515 0 103.032 0v-3.497h1.478a3.762 3.762 0 003.757-3.757v-8.419a3.766 3.766 0 00-3.76-3.758z" />
				<path d="M9.535 20.557v2.641h28.93v-2.641a5.78 5.78 0 013.007-5.068c-.345-2.941-2.849-5.233-5.882-5.233H12.41c-3.033 0-5.537 2.292-5.881 5.233a5.782 5.782 0 013.006 5.068z" />
			    <circle cx="24" cy="17" r="3" transform="rotate(-90,24,17)" />
				<text x="50%" y="40" dominant-baseline="top" text-anchor="middle">
					${this.error || this.forceError ? 'error' : 'loading'}
				</text>
			</svg>
		`
		/* eslint-enable max-len */
	}

	@Watch('src')
	resetLoadedImage() {
		this.$emit('loading')

		Promise.resolve(this.src)
			.then((img: ImageData) => {
				this.imageLoaded = true
				this.loadedImage = typeof img === 'string' ? img : img.src
			})
			.catch(e => {
				this.imageLoaded = false
				this.error = true

				if (this.handleError) {
					this.$handleError(e)
				} else {
					this.$emit('error', e)
				}
			})
			.finally(() => this.$emit('loaded'))
	}
}
