








































































































































































import md5 from 'js-md5'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import { debounce } from 'lodash'
import Draggable from 'vuedraggable'
import ImageData from '@/types/Image'
import truthy from '@/helpers/truthy'
import slugify from '@/helpers/slugify'
import ImagePicker from './ImagePicker.vue'
import keyIsNot from '@/helpers/key-is-not'
import PlatformType from '@/types/Platform'
import ImageEditor from './ImageEditModal.vue'
import Notification from '@/types/Notification'
import PlatformImage from './PlatformImage.vue'
import mounted from '@/types/decorators/mounted'
import PlaceholderImage from './PlaceholderImage.vue'
import PlatformDefinition from '@/types/definitions/Platform'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { faUser, faLock, faSpinner } from '@fortawesome/free-solid-svg-icons'

addFontAwesomeIcon(faSpinner)
addFontAwesomeIcon(faUser)
addFontAwesomeIcon(faLock)

@Component({
	components: {
		PlatformImage,
		Draggable,
		ImagePicker,
		ImageEditor,
		PlaceholderImage
	},

	computed: {
		platformTitle: {
			get() {
				return this.platform.title
			},
			set: debounce(function (title: string) {
				this.updatePlatformData({ title })
			}, 200)
		}
	}
})
export default class Platform extends Vue {
	@Prop({ type: String, required: true })
	readonly id: string

	protected showPlatformTitleInput: boolean = false
	public downloading: boolean = false
	public loading: number = 0

	protected notifications: string[] = []

	mounted() {
		this.resetPlatformTitleInput()
	}

	changeTitle(e) {
		this.updatePlatformData({ title: this.product.title })
		this.showPlatformTitleInput = true
		setTimeout(() => this.$refs.titleInput.focus(), 20)
	}

	blurTitleInput(e, compare) {
		if (e.target.value === this.product.title || !e.target.value) {
			this.showPlatformTitleInput = false
			this.platformTitle = ''
		}
	}

	@Watch('platformTitle')
	resetPlatformTitleInput(v) {
		if (v === this.product.title) {
			return
		}

		this.showPlatformTitleInput =
			document.activeElement === this.$refs.titleInput ||
			!!this.platformTitle
	}

	get product() {
		return this.$store.getters['product']
	}

	get platform() {
		return this.$store.getters['platforms/byId'](this.id)
	}

	set platform(v) {
		this.updatePlatform(v)
	}

	get imageIds() {
		return this.platformImages
			.map(i => (i && i.image ? i.image.id : null))
			.filter(i => !!i)
	}

	get remainingImages() {
		return Math.max(
			0,
			this.platform.settings.maxCount - this.platformImages.length
		)
	}

	get isBelowImageLimit() {
		return this.platform.settings.maxCount - this.platformImages.length >= 0
	}

	updatePlatform(v: PlatformType) {
		this.updatePlatformData(v)
	}

	updatePlatformData(v) {
		this.$store.commit(
			'platforms/UPDATE_PLATFORM',
			Object.assign({ id: this.platform.id }, v)
		)
	}

	get platformActive() {
		return this.platform.active
	}

	set platformActive(active: boolean) {
		this.updatePlatformData({ active })
	}

	get platformImages() {
		return this.platform.images.filter(truthy)
	}

	set platformImages(images) {
		return (this.platform.images = images)
	}

	@Watch('platformTitle')
	@Watch('platformImages', { deep: true })
	resetPlatformCustom() {
		this.platform.resetCustom()
	}

	reset() {
		this.notifications = []

		this.platform.resetImageSelection(this.$product.type === 'set')

		this.resetPlatformCustom()
	}

	get effectiveTitle() {
		return this.platformTitle || this.product.title
	}

	download() {
		this.downloading = true

		const zip = new JSZip()
		const fileName = slugify(this.effectiveTitle).substr(0, 245)
		const zipNamePrefix = `${this.product.sku}-${this.platform.id}`

		this.getFinalPlatformImages()
			.then(images =>
				images.forEach((i, ix) => {
					i && zip.file(`${fileName}-${ix}.jpg`, i.blob)
				})
			)
			.then(() => zip.generateAsync({ type: 'blob' }))
			.then(file =>
				saveAs(
					file,
					`${zipNamePrefix}-${md5(
						new Date().getMilliseconds().toString()
					).substr(0, 8)}.zip`
				)
			)
			.catch(this.$handleError)
			.finally(() => (this.downloading = false))
	}

	private getFinalPlatformImages() {
		return Promise.all(
			this.platformImages.map((image, ix) => {
				if (!image.image) {
					return Promise.resolve()
				}

				return this.$store
					.dispatch('images/GET_IMAGE_COMPOSITION', {
						id: image.image.id,
						rule: image.rule,
						dimensions: this.platform.settings.format,
						globalPadding: this.$settings.platforms.padding,
						boundingBoxPadding: this.$settings.gallery
							.boundingBoxPadding
					})
					.catch(() => undefined)
			})
		)
	}

	removeImage(index: number) {
		this.$store
			.dispatch('platforms/REMOVE_IMAGE', {
				id: this.platform.id,
				index
			})
			.then(() => {
				this.notifications = this.notifications
					.filter(i => i.ix !== index)
					.map(({ ix, message }) =>
						ix > index ? { message, ix: ix - 1 } : { ix, message }
					)
			})
	}

	byId(id: string) {
		return this.$store.getters['images/byId'](id)
	}

	addImages() {
		this.$refs.picker.openModal().then(ids => {
			ids.forEach(id =>
				this.$store.dispatch('platforms/ADD_IMAGE', {
					id: this.platform.id,
					image: id
				})
			)
		})
	}

	editImage(ix) {
		this.$refs.editor.openModal(this.platformImages[ix].rule).then(rule => {
			if (!rule) {
				return
			}

			this.$store.dispatch('platforms/EDIT_IMAGE', {
				index: ix,
				id: this.platform.id,
				rule
			})
		})
	}

	handleError(e: Error, imageIndex: number) {
		if (e.constructor.name === 'Notification') {
			return this.$handleError(e)
		}

		if (!this.notifications.map(i => i.message).includes(e)) {
			this.notifications.push({
				ix: imageIndex,
				message: e
			})
		}
	}
}
