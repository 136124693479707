import { get } from 'lodash'

export default function sortBy(
	prop: string,
	order: 'asc' | 'desc' = 'asc',
	normalizeString: boolean = false
) {
	let sortOrder = [-1, 1]
	let normalizeOp = (a: any) => a

	if (order.toLowerCase() === 'desc') {
		sortOrder = sortOrder.reverse()
	}

	if (normalizeString) {
		normalizeOp = (a: string) => a.toLowerCase()
	}

	return (a: { [key: string]: any }, b: { [key: string]: any }) => {
		return normalizeOp(get(a, prop)) < normalizeOp(get(b, prop))
			? sortOrder[0]
			: sortOrder[1]
	}
}
