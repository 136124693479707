













































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Checkbox extends Vue {
	@Prop({ type: Boolean })
	readonly value!: boolean | null

	@Prop({ type: String })
	readonly name!: string

	@Prop({ type: Boolean })
	readonly startsUndefined!: boolean

	@Prop({ type: Boolean })
	readonly controlledExternally!: boolean

	get realValue() {
		return this.value
	}

	set realValue(v) {
		this.$emit('input', v)
	}

	changeValue(e) {
		if (this.controlledExternally) return

		this.realValue = !this.realValue
	}
}
