



































































































import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Notifications extends Vue {
	get notifications() {
		return this.$store.state.notifications.notifications
	}

	get notificationCount() {
		return this.notifications.length
	}

	dismissNotification(notification: string) {
		this.$store.commit('notifications/REMOVE', notification)
	}

	clearNotifications() {
		this.$store.commit('notifications/CLEAR')
	}

	@Watch('notificationCount')
	scrollUp(v, o) {
		if (v > 0 && this.notifications.slice(-1).pop().scrollUp) {
			this.$nextTick().then(() => {
				const el = this.$el.querySelector('.notification:last-child')

				const bb = (el && el.getBoundingClientRect()) || {
					top: 0,
					left: 0,
					right: 0,
					bottom: 0
				}

				if (
					bb.top < 0 ||
					bb.left < 0 ||
					bb.right >
						(window.innerWidth ||
							document.documentElement.clientWidth) ||
					bb.bottom >
						(window.innerHeight ||
							document.documentElement.clientHeight)
				) {
					el.scrollIntoView({
						behavior: 'smooth',
						block: 'nearest',
						inline: 'center'
					})
				}
			})
		}
	}
}
