
































































































































































































import EventBus from '@/helpers/event-bus'
import DisplayItem from './DisplayItem.vue'
import PromisedImage from './PromisedImage.vue'
import ProductImage from '@/types/product-image'
import scaleDimensions from '@/helpers/scale-dimensions'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

addFontAwesomeIcon(faSpinner)

@Component({ components: { DisplayItem, PromisedImage } })
export default class ImageGalleryItem extends Vue {
	private EventBus: typeof EventBus = EventBus
	public src: Promise<ImageData> = null

	@Prop({ type: Boolean, default: true })
	readonly rounded: boolean

	@Prop({ type: Object, required: true })
	readonly image: ProductImage

	@Prop({ type: String, default: 'li' })
	readonly element: string

	@Prop({ type: Boolean, default: false })
	readonly showUncategorize: boolean

	@Prop({ type: Boolean, default: true })
	readonly showDelete: boolean

	@Prop({ type: Boolean, default: false })
	readonly showReannotate: boolean

	private showingBoundingBoxDefiner = false
	private definedBoundingBox = {
		top: 0.25,
		right: 0.75,
		bottom: 0.75,
		left: 0.25
	}

	created() {
		this.resetSource()
	}

	mounted() {
		if (
			this.image.objects.length &&
			this.image.objects[0].mid === 'custom'
		) {
			this.definedBoundingBox = {
				top: this.image.objects[0].boundingPoly.normalizedVertices[0].y,
				left: this.image.objects[0].boundingPoly.normalizedVertices[0]
					.x,
				bottom: this.image.objects[0].boundingPoly.normalizedVertices[2]
					.y,
				right: this.image.objects[0].boundingPoly.normalizedVertices[2]
					.x
			}

			this.showingBoundingBoxDefiner = true
		}
	}

	@Watch('image')
	resetSource() {
		this.src = this.image.image.src
	}

	getImageObjectPosition(obj: LocalizedObjectAnnotation) {
		if (obj.mid === '') {
			return 'display:none;'
		}

		const padding = this.$settings.gallery.boundingBoxPadding

		const top = Math.max(
			0,
			(obj.boundingPoly.normalizedVertices[0].y || 0) - padding
		)
		const left = Math.max(
			0,
			(obj.boundingPoly.normalizedVertices[0].x || 0) - padding
		)
		const right = Math.min(
			1,
			1 - ((obj.boundingPoly.normalizedVertices[2].x || 0) + padding)
		)
		const bottom = Math.min(
			1,
			1 - ((obj.boundingPoly.normalizedVertices[2].y || 0) + padding)
		)

		return `
			top: ${top * 100}%;
			left: ${left * 100}%;
			right: ${right * 100}%;
			bottom: ${bottom * 100}%;
			padding: ${top && left ? padding * 100 : 2}%;
			${this.$refs.image && this.$refs.imageLoaded ? '' : 'display:none;'}
		`
	}

	clearCache() {
		const oldText = this.$refs['clear-cache'].innerText

		this.$store.commit('images/CLEAR_CACHE', this.image)

		this.$refs['clear-cache'].innerText = '✓'
		this.$refs['clear-cache'].classList.remove('text-gray-500')
		this.$refs['clear-cache'].classList.add('text-green-500', 'text-lg')

		setTimeout(() => {
			this.$refs['clear-cache'].innerText = oldText
			this.$refs['clear-cache'].classList.add('text-gray-500')
			this.$refs['clear-cache'].classList.remove(
				'text-green-500',
				'text-lg'
			)
		}, 2500)
	}

	showBoundingBoxDefiner() {
		this.$store.commit('images/UPDATE_IMAGE', {
			id: this.image.id,
			categories: [this.image.category, this.image.subCategory]
		})

		this.$store.commit('images/REMOVE_BOUNDING_BOX', this.image.id)
		this.$store.commit('images/ADD_BOUNDING_BOX', this.image.id)
		this.updateBoundingBox()

		this.showingBoundingBoxDefiner = true
	}

	hideBoundingBoxDefiner() {
		this.$store.commit('images/REMOVE_BOUNDING_BOX', this.image.id)

		this.showingBoundingBoxDefiner = false
	}

	@Watch('definedBoundingBox.top')
	@Watch('definedBoundingBox.left')
	@Watch('definedBoundingBox.right')
	@Watch('definedBoundingBox.bottom')
	@Watch('definedBoundingBox')
	updateBoundingBox() {
		this.$store.commit('images/UPDATE_BOUNDING_BOX', {
			id: this.image.id,
			bb: this.definedBoundingBox
		})
	}

	reannotate() {
		this.$store.dispatch('images/REANNOTATE_IMAGE', this.image.id)
	}
}
