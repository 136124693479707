import Point from './Point'

export default class Area implements AreaInterface, DimensionType {
	constructor(public start: PointType, public end: PointType) {}

	get width(): number {
		return Math.abs(this.end.x - this.start.x)
	}

	get height(): number {
		return Math.abs(this.end.y - this.start.y)
	}

	get area(): number {
		return this.width * this.height
	}

	get dimensions(): DimensionType {
		return {
			width: this.width,
			height: this.height
		}
	}

	integrate(maxDimensions: DimensionType): Area {
		const start = new Point(
			Math.max(this.start.x, 0),
			Math.max(this.start.y, 0)
		)

		const end = new Point(
			Math.min(this.end.x, maxDimensions.width),
			Math.min(this.end.y, maxDimensions.height)
		)

		return new Area(start, end)
	}

	/**
	 * Enlarges the area by a given factor relative to the given dimensions.
	 *
	 * @param enhancementFactor Factor > 0 by that amount the coordinates should be enhanced
	 * @param dimensions Dimensions up until the enhancement should take place
	 */
	enlarge(enhancementFactor: number) {
		const start = new Point(
			this.start.x - (enhancementFactor / 2) * this.width,
			this.start.y - (enhancementFactor / 2) * this.height
		)
		const end = new Point(
			this.end.x + (enhancementFactor / 2) * this.width,
			this.end.y + (enhancementFactor / 2) * this.height
		)

		return new Area(start, end)
	}

	/**
	 * Enlarges the area by a given factor relative to the given dimensions.
	 *
	 * @param enhancementFactor Factor > 0 by that amount the coordinates should be enhanced
	 * @param dimensions Dimensions up until the enhancement should take place
	 */
	enhance(enhancementFactor: number, dimensions: DimensionType) {
		const start = new Point(
			this.start.x - enhancementFactor * dimensions.width,
			this.start.y - enhancementFactor * dimensions.height
		)
		const end = new Point(
			this.end.x + enhancementFactor * dimensions.width,
			this.end.y + enhancementFactor * dimensions.height
		)

		return new Area(start, end).integrate(dimensions)
	}

	includes(point: PointType): boolean {
		return (
			point.x >= this.start.x &&
			point.x <= this.end.x &&
			point.y >= this.start.y &&
			point.y <= this.end.y
		)
	}

	overlaps(area: AreaInterface): boolean {
		return (
			this.includes(area.start) ||
			this.includes(area.end) ||
			area.includes(this.start) ||
			area.includes(this.end)
		)
	}

	surrounds(area: AreaInterface): boolean {
		return this.includes(area.start) && this.includes(area.end)
	}

	getOverlappingArea(area: AreaInterface): number {
		if (!this.overlaps(area)) {
			return 0
		}

		if (this.surrounds(area)) {
			return area.area
		}

		if (area.surrounds(this)) {
			return this.area
		}

		if (this.includes(area.start)) {
			return new Area(
				area.start,
				new Point(Math.min(this.end.x, area.end.x), this.end.y)
			).area
		}

		if (this.includes(area.end)) {
			return new Area(
				new Point(Math.max(this.start.x, area.start.x), this.start.y),
				area.end
			).area
		}

		return area.getOverlappingArea(this)
	}

	correct(imageDimensions: DimensionType, threshold: number): Area | false {
		const requiredMove =
			this.start.y < 0
				? this.start.y * -1
				: imageDimensions.height - (this.height + this.start.y)

		if (Math.abs(requiredMove) > threshold * this.height) {
			// eslint-disable-next-line no-console
			console.warn(
				// eslint-disable-next-line max-len
				`Cannot correct image, because the required correction is above the defined threshold: ${Math.abs(
					requiredMove
				) / this.height} (${requiredMove}px)`
			)

			return false
		}

		if (this.height > imageDimensions.height) {
			// eslint-disable-next-line no-console
			console.warn(`Image out of bounds detected in area correction`)

			return false
		}

		return this.move(0, requiredMove)
	}

	move(moveX: number = 0, moveY: number = 0) {
		return new Area(
			new Point(this.start.x + moveX, this.start.y + moveY),
			new Point(this.end.x + moveX, this.end.y + moveY)
		)
	}

	static getCombinedArea(...areas: Area[]): number {
		const usedAreas: Area[] = []
		let area = 0

		areas.forEach(a => {
			area += a.area

			usedAreas.forEach(ua => {
				area -= ua.getOverlappingArea(a)
			})

			usedAreas.push(a)
		})

		return area
	}
}
