export default function imageFromSvg(
	svg: TemplateStringsArray,
	...interpolations: string[]
) {
	interpolations.push('')

	const string = svg.map((p, i) => p + interpolations[i]).join('')
	const image = new Image()

	return new Promise<HTMLImageElement>((resolve, reject) => {
		image.src = svgToBase64(string)

		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', () => reject())
	})
}

export function svgToBase64(svg: string) {
	return 'data:image/svg+xml;base64,' + btoa(svg)
}
