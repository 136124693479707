
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import addFontAwesomeIcon from '../helpers/add-font-awesome-icon'
import toArray from '../helpers/to-array'
import { debounce } from 'lodash'

addFontAwesomeIcon(faCloud)

const baseHtmlTag = document.getElementsByTagName('html')[0]

@Component
export default class DropZone extends Vue {
	protected show: boolean = false
	protected counter: number = 0

	@Prop({ type: Boolean, default: false })
	readonly useOwnDrop: boolean

	mounted() {
		document.addEventListener('drop', this.drop)
		document.addEventListener('dragover', this.dragOver)
		document.addEventListener('dragenter', this.dragEnter)
		document.addEventListener('dragleave', this.dragLeave)
	}

	dragEnter(e: DragEvent & { dataTransfer: DataTransferItemList }) {
		this.counter++

		if (e.dataTransfer.types.includes('Files')) {
			e.preventDefault()

			this.show = true

			this.$parent.$el.classList.add('files-dragging')
		}
	}

	dragLeave(e: DragEvent) {
		e.preventDefault()

		this.counter--

		if (e.target === baseHtmlTag || !this.counter) {
			this.show = false

			this.$parent.$el.classList.remove('files-dragging')
		}
	}

	resetCounter() {
		this.show = false
		this.counter = 0
	}

	drop(e: DragEvent) {
		e.preventDefault()
		e.stopPropagation()

		this.$emit('new-drop', e)

		this.resetCounter()
	}
}
