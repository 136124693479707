



























































import Modal from './Modal.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { Modal } })
export default class IdPromptModal extends Vue {
	private resolve: (number) => any = () => null
	private reject: () => any = () => null

	protected skus: number[] | string[] = []

	public manualSku: number = null

	public open(skus: number[] | string[]) {
		this.skus = skus

		return new Promise((resolve, reject) => {
			this.resolve = resolve
			this.reject = reject

			this.$refs.base.open()

			this.registerListeners()
		})
	}

	public close() {
		this.deregisterListeners()

		this.$refs.base.close()
	}

	private registerListeners() {
		document.addEventListener('keyup', this.keyUpListen)
	}

	private deregisterListeners() {
		document.removeEventListener('keyup', this.keyUpListen)
	}

	protected select(sku: number) {
		this.resolve(sku)

		this.close()
	}

	private focusManualInput() {
		this.$refs['manual-input'].focus()
	}

	private keyUpListen(e) {
		e.stopPropagation()

		const key = e.key

		if (this.skus[key - 1]) {
			this.select(this.skus[key - 1])
		}

		if (
			key === 'Enter' &&
			document.activeElement === this.$refs['manual-input']
		) {
			this.select(this.manualSku)
		}

		if (key === '/') {
			this.focusManualInput()
		}
	}
}
