import { Store } from 'vuex'
import ImageData from '@/types/ImageData'
import loadImage from '@/helpers/load-image'
import ImageAnnotation from '@/types/ImageAnnotation'
import { scaleDimensionsMax } from '@/helpers/scale-dimensions'

export default class BrandLogoAnnotation extends ImageAnnotation {
	constructor(private position: position = { top: 50, left: 50 }) {
		super(Array.from(arguments))
	}

	annotate(image: ImageData, store: Store<any>): Promise<ImageData> {
		const brandLogo = store.getters.product.brand.logo

		if (!brandLogo) {
			console.warn('Product does not have a brand logo.') // eslint-disable-line no-console

			return Promise.resolve(image)
		}

		return loadImage(brandLogo).then(({ img, dimensions }) => {
			const logoDimensions =
				dimensions.width > dimensions.height
					? { width: 0.45, height: 0.24 }
					: { width: 0.3, height: 0.3 }

			const size = scaleDimensionsMax(dimensions, {
				width: image.dimensions.width * logoDimensions.width * 0.8,
				height: image.dimensions.height * logoDimensions.height * 0.8
			})

			return image.getCanvasWithImage().then(([canvas, ctx]) => {
				ctx.drawImage(
					img,
					...this.getPositioning(
						this.position,
						size,
						image.dimensions
					)
				)

				return new ImageData(
					canvas.toDataURL('image/jpeg', 1),
					image.dimensions
				)
			})
		})
	}
}
