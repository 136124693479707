



























































































import { Component, Vue } from 'vue-property-decorator'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'

addFontAwesomeIcon(faCircleNotch)

@Component({
	props: {
		loading: {
			type: Boolean,
			default: false
		},

		progress: {
			type: [Number],
			default: false
		},

		progressMax: {
			type: Number,
			default: 0
		}
	}
})
export default class Navbar extends Vue {
	get percentage() {
		return this.progress / this.progressMax
	}

	logout() {
		this.$emit('clear')

		this.$store.dispatch('LOGOUT')

		this.$router.push(this.$route.fullPath + '/')
	}

	get version() {
		return process.env.VUE_APP_VERSION
	}
}
