





































































































































import sum from '@/helpers/sum'
import { cloneDeep } from 'lodash'
import axios from '@/helpers/axios'
import { tap } from '@/helpers/tap'
import Product from '@/types/Product'
import flatten from '@/helpers/flatten'
import FileDrop from '@/mixins/file-drop'
import EventBus from '@/helpers/event-bus'
import Navbar from '@/components/Navbar.vue'
import ProductImage from '@/types/ProductImage'
import Platforms from '@/components/Platforms.vue'
import SaveButton from '@/components/SaveButton.vue'
import BulkButton from '@/components/BulkButton.vue'
import ProductDisplay from '@/components/Product.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import ImageGallery from '@/components/ImageGallery.vue'
import { ImageRule } from '@/types/definitions/ImageRule'
import IdPromptModal from '@/components/IdPromptModal.vue'
import Notifications from '@/components/Notifications.vue'
import PermanentImage from '@/state/helper/PermanentImage'
import BigIdInputModal from '@/components/BigIdInputModal.vue'
import { PlatformType } from '@/types/definitions/PlatformType'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import ImageLoadingModal from '@/components/ImageLoadingModal.vue'
import BulkImageEditModal from '@/components/BulkImageEditModal.vue'
import { NotificationLevel } from '@/types/definitions/NotificationLevel'

addFontAwesomeIcon(faFileUpload)

@Component({
	name: 'Home',

	components: {
		Notifications,
		Navbar,
		ConfirmModal,
		ImageGallery,
		Platforms,
		ProductDisplay,
		IdPromptModal,
		BigIdInputModal,
		ImageLoadingModal,
		SaveButton,
		BulkButton,
		BulkImageEditModal
	}
})
export default class Home extends Mixins(FileDrop) {
	protected loading: { [key: string]: number | boolean } = {
		images: 0,
		annotate: 0,
		product: 0
	}
	protected progress = 0
	protected progressMax = 0
	protected loadImages = true

	mounted() {
		EventBus.$on('annotate', this.annotate.bind(this))

		this.loadProduct()

		document.addEventListener('keyup', this.keyUp)
	}

	keyUp(e) {
		if (e.key === '/') {
			this.selectProduct()
		}
	}

	selectProduct() {
		this.$refs['id-input']
			.open()
			.then(({ sku, loadImages, clearImages }) => {
				if (clearImages && this.product) {
					this.clearImages()
				}

				this.loadImages = loadImages

				if (sku && (!this.product || sku !== this.product.sku)) {
					this.$router.push(`/${sku}`)
				}
			})
	}

	get showLoading() {
		return Object.values(this.loading).reduce(sum, 0) > 0
	}

	get imageCount() {
		return this.$store.getters['images/imageCount']
	}

	get byCategoryAndSub() {
		return this.$store.getters['images/byCategoryAndSubCategory']
	}

	get byCategory() {
		return this.$store.getters['images/byCategory']
	}

	get categories() {
		return this.$store.getters['images/categories']
	}

	get byId() {
		return this.$store.getters['images/byId']
	}

	get product() {
		return this.$store.state.product.product
	}

	annotate(id: ProductImage['id']) {
		this.loading.annotate++

		const image = this.byId(id)

		image.objects = []

		this.$store
			.dispatch('images/REANNOTATE_IMAGE', id)
			.then(() => this.loading.annotate--)
	}

	@Watch('$route')
	async loadProduct() {
		const sku = this.$route.params.sku
		const platforms = this.$store.state.platforms.platforms
		const limitPlatforms = Object.values(platforms).map(
			platform => `platforms[]=${platform.id}`
		)

		if (sku) {
			this.$refs['image-loading'].open()

			this.loading.product++

			await axios
				.get(`product/${sku}?${limitPlatforms.join('&')}`)
				.then(response => response.data)
				.then(
					tap(product =>
						Object.setPrototypeOf(product, Product.prototype)
					)
				)
				.then(product => {
					const platforms = product.platforms
					const images = product.images
					const originals = product.originals

					delete product.platforms
					delete product.images
					delete product.originals

					this.$store.commit('SET_PRODUCT', product)

					let chain = Promise.resolve()

					if (product.children.length) {
						chain = chain.then(() =>
							this.$store.dispatch('HANDLE_SET_PRODUCT', product)
						)
					}

					if (this.loadImages) {
						chain = chain
							.then(() =>
								this.$store.dispatch('images/LOAD', {
									originals,
									images
								})
							)
							.then(() =>
								this.$store.dispatch('platforms/LOAD', {
									platforms,
									images
								})
							)
					}

					return chain
				})
				.catch(this.$handleError.bind(this))
				.finally(() => {
					this.loading.product--

					this.$refs['image-loading'].close()

					this.loadImages = true
				})
		} else {
			this.$store.commit('SET_PRODUCT', null)
		}
	}

	promptForIds(ids: string[] | number[]) {
		if (ids.length === 0) {
			return this.selectProduct()
		}

		this.$refs['prompt-id'].open(ids.map(id => parseInt(id))).then(id => {
			if (id) {
				this.$router.replace(`/${id}`)
			}
		})
	}

	clearAll() {
		this.$store.commit('CLEAR_PRODUCT')

		this.clearImages()

		this.$store.commit('notifications/CLEAR')

		if (this.$route.path !== '/') {
			this.$router.push('/')
		}
	}

	clearImages() {
		this.$store.commit('platforms/CLEAR')
		this.$store.commit('images/CLEAR')
	}

	performBulkActions() {
		this.$refs['bulk-editor'].openModal().then(globalRule => {
			if (!globalRule) {
				return
			}

			this.$store.getters['platforms/selectedImages'].forEach(
				({ ix, platform, image: { rule: originalRule } }) => {
					const rule = cloneDeep(originalRule)

					rule.overlays = rule.overlays || []

					if (globalRule.useAsIs !== null) {
						rule.useAsIs = globalRule.useAsIs
					}

					if (globalRule.allowFullPicture !== null) {
						rule.allowFullPicture = globalRule.allowFullPicture
					}

					if (globalRule.overlays) {
						globalRule.overlays.forEach(overlay => {
							let found = rule.overlays.findIndex(
								o => o.name === overlay.name
							)

							if (found !== -1) {
								if (overlay.args === 'delete') {
									rule.overlays.splice(found, 1)
								} else {
									rule.overlays[found] = cloneDeep(overlay)
								}
							} else if (overlay.args !== 'delete') {
								rule.overlays.push(cloneDeep(overlay))
							}
						})
					}

					if (globalRule.padding) {
						rule.padding = cloneDeep(globalRule.padding)
					}

					this.$store.dispatch('platforms/EDIT_IMAGE', {
						index: ix,
						id: platform,
						rule
					})
				}
			)
		})
	}
}
