










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DisplayItem extends Vue {
	@Prop({ type: Boolean, default: true })
	readonly rounded: boolean

	@Prop({ type: String, default: 'li' })
	readonly element: string
}
