export default function chunk<T>(chunkSize: number) {
	return (carry: T[][], currentValue: T): T[][] => {
		if (!carry.length || carry[carry.length - 1].length >= chunkSize) {
			carry.push([])
		}

		carry[carry.length - 1].push(currentValue)

		return carry
	}
}
