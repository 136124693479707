








































































































import { cloneDeep } from 'lodash'
import Checkbox from './Checkbox.vue'
import PromisedImage from './PromisedImage.vue'
import ImageRule from '@/types/definitions/ImageRule'
import scaleDimensions from '@/helpers/scale-dimensions'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import addFontAwesomeIcon from '@/helpers/add-font-awesome-icon'
import PlatformImageType from '@/types/definitions/PlatformImage'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

addFontAwesomeIcon(faEllipsisH)

@Component({ components: { PromisedImage, Checkbox } })
export default class PlatformImage extends Vue {
	@Prop({ type: Object, required: true })
	readonly image: PlatformImageType

	@Prop({ type: Number, required: true })
	readonly index: number

	@Prop({ type: Object, required: true })
	readonly platform: Platform

	protected imagePreview: string = false
	protected isHavingError: boolean = false
	protected dimensions: dimensions = { width: 0, height: 0 }

	created() {
		this.dimensions = scaleDimensions(this.platform.settings.format, {
			width: this.$settings.platforms.imageSize
		})
	}

	get rule() {
		return this.image && this.image.rule
	}

	get platformImage() {
		return this.image && this.image.image
	}

	@Watch('platformImage', { immediate: true })
	@Watch('rule.overlays', { deep: true })
	@Watch('rule.padding', { deep: true })
	@Watch('rule.allowFullPicture')
	@Watch('rule.useAsIs')
	public resetImagePreview() {
		if (this.platformImage) {
			this.isHavingError = false

			return (this.imagePreview = this.$store.dispatch(
				'images/GET_IMAGE_COMPOSITION',
				{
					id: this.platformImage.id,
					rule: this.rule,
					dimensions: this.platform.settings.format,
					globalPadding: this.$settings.platforms.padding,
					boundingBoxPadding: this.$settings.gallery
						.boundingBoxPadding
				}
			))
		}

		this.imagePreview = false
	}

	protected handleError(ev) {
		if (this.isHavingError) {
			// eslint-disable-next-line no-console
			console.error(ev)

			return
		}

		try {
			this.isHavingError = true

			this.imagePreview = Promise.resolve(this.platformImage.image.src)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}

		this.$emit('error', ev)
	}
}
