

























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
	@Prop({ default: 50, type: Number })
	readonly darkness: number

	@Prop({ default: 10, type: Number })
	readonly zIndex: number

	protected isOpen: boolean = false

	public open() {
		this.isOpen = true

		this.$root.showingModal++
	}

	public close() {
		this.isOpen = false

		this.$root.showingModal--
	}
}
