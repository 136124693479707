import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import PageTitle from './plugins/router.page-title'
import WaitForStore from './plugins/router.wait-for-store'
import CheckAuthentication from './plugins/router.check-authentication'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/:sku',
			component: Home
		}
	]
})

router.beforeEach(WaitForStore)
router.beforeEach(CheckAuthentication)
router.afterEach(PageTitle)

export default router
