import Vue from 'vue'
import store from '@/store'
import Product from './Product'
import { cloneDeep } from 'lodash'
import truthy from '@/helpers/truthy'
import sortBy from '@/helpers/sort-by'
import realMod from '@/helpers/real-mod'
import ProductImage from './ProductImage'
import { ProductState } from '@/state/product'
import { ImageRule } from './definitions/ImageRule'
import { PlatformType } from './definitions/PlatformType'
import { PlatformImage } from './definitions/PlatformImage'
import { StoredProductType } from './definitions/StoredProductType'
import { ProductImageInterface } from './definitions/ProductImageInterface'

export default class Platform implements PlatformType {
	constructor(
		public id: PlatformType['id'],
		public settings: PlatformType['settings'],
		public custom: PlatformType['custom'],
		private _images: PlatformType['images'],
		public active: PlatformType['active'] = true,
		public title?: Product['attributes']['title']
	) {
		this.resetCustom()
	}

	get images() {
		return this._images
	}

	set images(images) {
		const keys = Object.keys(images)

		keys.forEach(ix => Vue.set(this._images, ix, images[<any>ix]))

		for (let i = keys.length; i < this.settings.maxCount; i++) {
			Vue.delete(this._images, i)
		}
	}

	get data() {
		return {
			id: this.id,
			custom: this.custom ? 1 : 0,
			title: this.title || '',
			active: this.active ? 1 : 0
		}
	}

	get storable(): Omit<PlatformType, 'images' | 'storable' | 'data'> & {
		_images: Array<{ image: string; rule: ImageRule } | undefined | null>
	} {
		return {
			id: this.id,
			settings: this.settings,
			custom: this.custom,
			title: this.title,
			active: this.active,
			_images: Object.values(this.images).map(
				(i: PlatformImage | null) =>
					i &&
					i.image &&
					i.rule && {
						image: (i.image as ProductImage).id,
						rule: cloneDeep(i.rule)
					}
			)
		}
	}

	public static fromStorable(
		data: Partial<Platform['storable']>
	): PlatformType {
		data = Object.assign(
			{
				_images: [],
				title: null,
				custom: false,
				active: true
			},
			data
		)

		Object.setPrototypeOf(data, Platform.prototype)

		if (!data._images) {
			data._images = []
		}

		data._images = data._images.map(i => {
			if (i) {
				i.image = store.getters['images/byId'](i)
			}

			return i
		})

		return (<any>data) as PlatformType
	}

	private imagesHaveDifference(
		firstImageIdList: Array<string | null>,
		secondImageIdList: Array<string | null>
	) {
		return firstImageIdList.find((item, ix) => {
			return item !== secondImageIdList[ix]
		})
	}

	public resetCustom() {
		this.custom = !!(
			this.title ||
			(this.images.length &&
				(this.images.find(i => i && i.rule && i.rule.custom) ||
					this.imagesHaveDifference(
						this.getAutoImages().map(i =>
							i.image ? i.image.id : null
						),
						this.images.map(i => i && (i.image ? i.image.id : null))
					)))
		)
	}

	private findImage(
		rules: ImageRule,
		used: ProductImageInterface['id'][]
	): ProductImage | null {
		const images = store.getters['images/byCategoryAndSubCategory'](
			...rules.category
		)
		const ix = rules.index || 0

		let winner = images[ix] || null

		if (winner && used.includes(winner.id)) {
			if (rules.allowFindNext) {
				winner = null

				for (let i = ix; i < images.length; i++) {
					if (!used.includes(images[i].id)) {
						winner = images[i]

						break
					}
				}
			} else {
				winner = null
			}
		}

		return winner
	}

	protected getFillImages(
		used: ProductImage['id'][],
		isSet: boolean = false
	): PlatformImage[] {
		if (isSet) {
			return this.getSetFillImages()
		}

		const images: PlatformImage[] = []
		const fillRule = cloneDeep(this.settings.fillRule)

		if (fillRule) {
			fillRule.map(r => (r.allowFindNext = true))

			let found: PlatformImage | false

			while ((found = this.findImageFromRules(fillRule, used))) {
				// @ts-ignore
				used.push(found.image.id)

				images.push(found)
			}
		}

		return images
	}

	public getAutoImages(
		rules = this.settings.rules,
		isSet: boolean = false
	): PlatformImage[] {
		const [below, above] = Object.keys(rules)
			.map(k => ~~k)
			.reduce(
				(c, a) => {
					if (a < 0) {
						c[0].push(a)
					} else {
						c[1].push(a)
					}

					return c
				},
				[[], []] as [number[], number[]]
			)

		let images: PlatformImage[] = []

		above.sort().forEach(key => {
			const found = this.findImageFromRules(
				rules[key],
				images.map(i => (i.image as ProductImage).id)
			)

			if (found) {
				images.push(found)
			}
		})

		const belowImages = {} as { [position: number]: PlatformImage }

		below.sort().forEach(key => {
			const found = this.findImageFromRules(
				rules[key],
				images
					.concat(Object.values(belowImages))
					.map(i => (i.image as ProductImage).id)
			)

			if (found) {
				belowImages[key] = found
			}
		})

		// Fill in filler images
		images = images.concat(
			this.getFillImages(
				images
					.concat(Object.values(belowImages))
					.map(i => (i.image as ProductImage).id),
				isSet
			)
		)

		images = images
			.filter(i => Object.keys(i).length)
			.slice(0, this.settings.maxCount)

		Object.keys(belowImages)
			.map(k => parseInt(k))
			.forEach(key => {
				if (truthy(belowImages[key])) {
					const position = realMod(key, this.settings.maxCount)

					images[position] = belowImages[key]
				}
			})

		const resetImages = [] as typeof images

		images
			.slice(0, this.settings.maxCount)
			.forEach(i => resetImages.push(i))

		return resetImages
	}

	public findImageFromRules(
		rules: ImageRule[],
		used: ProductImageInterface['id'][]
	): false | PlatformImage {
		for (const rule of rules) {
			const image = this.findImage(rule, used)

			if (image) {
				return {
					image,
					rule,
					selected: false
				}
			}
		}

		return false
	}

	public resetImageSelection(isSet: boolean = false) {
		let images = this.getAutoImages(
			isSet ? this.settings.setRules : this.settings.rules,
			isSet
		)

		if (!images.find(i => !!i.image)) {
			images = []
		}

		this.images = images
	}

	private getSetFillImages() {
		// @ts-ignore
		const children = store.state.product
			.children as ProductState['children']

		const sortedChildren = Object.values(children)
			.sort(sortBy('id'))
			.sort(sortBy('kind.weight', 'desc'))

		// Create filler array with images of the child products rotating for position
		return [...Array(this.settings.maxCount - 1)]
			.map((_ix: any, order: number) =>
				sortedChildren.map((child: StoredProductType) => {
					const image = (child.images[this.id] || []).find(
						i => i.order === order + 1
					)

					if (image) {
						if (typeof image.rule === 'string') {
							image.rule = JSON.parse(image.rule)
						}

						return {
							image: store.getters['images/byId'](image.original),
							rule: image.rule as ImageRule,
							selected: false
						} as PlatformImage
					}

					return {}
				})
			)
			.flat() as PlatformImage[]
	}
}
