import Worker from 'worker-loader!@/workers/bounding-box.ts'

export default class PromiseWorker<I, O> {
	private worker?: Worker

	constructor(public keepOpen: boolean = true) {}

	static once() {
		return PromiseWorker.get(false)
	}

	static get(keepOpen?: boolean) {
		return new PromiseWorker(keepOpen)
	}

	run(arg?: I): Promise<O> {
		return new Promise((resolve, reject) => {
			this.worker = new Worker()

			this.worker.addEventListener('message', this.reply(resolve))
			this.worker.addEventListener('error', this.reply(reject))

			this.worker.postMessage(arg)
		})
	}

	private reply(callback: Function): (e: MessageEvent | any) => any {
		return (e: MessageEvent | ErrorEvent) => {
			if (!this.keepOpen) {
				;(this.worker as Worker).terminate()
			}

			if (typeof (e as MessageEvent).data !== 'undefined') {
				e = (e as MessageEvent).data
			} else {
				e = e as ErrorEvent
			}

			return callback(e)
		}
	}
}
